import {authTypes, UserguideCardProps} from "../../shared/interfaces";

var platform = require('platform');

export const getKnownBrowserName = () => {
    let acceptedValues = ["chrome", "firefox", "edge", "safari", "opera", "microsoft edge"]
    if (platform.name) {
        let val;
        val = platform.name.trim();
        if (acceptedValues.includes(val.toLowerCase())) {
            return val;
        }
    }
    return ""
}

export const getKnownProductName = () => {
    //"BlackBerry", "Galaxy S4", "Lumia", "iPad", "iPod", "iPhone", "Kindle", "Kindle Fire", "Nexus", "Nook", "PlayBook", "TouchPad" and "Transformer"
    let acceptedValues = ["ipad", "iphone"]
    if (platform.product) {
       const product = platform.product.trim();
        if (acceptedValues.includes(product.toLowerCase())) {
            return product;
        }
    }
    return null;
}

function innerGetCurrentPlatform(acceptedValues : string[], valProducer: () => string) {
    if(platform.os && platform.os.family){
        let val;
        val = valProducer();
        if(acceptedValues.includes(val)){
            return val;
        }
    }
    return "other"
}


export const getCurrentPlatform = () => {
    return innerGetCurrentPlatform(["Windows", "OS X", "Android", "iOS"],() => platform.os.family)
}

export const getCurrentPlatformTrimmedLowercase = () => {
    return innerGetCurrentPlatform(["windows", "osx", "android", "ios"], () =>  platform.os.family.replace(/\s/g, "").toLowerCase())
}

export const capitalizeFirstLetter = (str: string) => {
    if(!str){
        return "";
    }
    return str.charAt(0).toUpperCase() + str.slice(1)
}

export const activateWindowLeaveModal = () => {
    window.onbeforeunload = function () {
        return "";
    }
}

export const deactivateWindowLeaveModal = () => {
    window.onbeforeunload = null;
}

export const openLinkSafely = (url: string) => {
    window.open(url, "_blank", "noopener, noreferrer");
}

export const openLinkSafelySameTarget = (url: string) => {
    deactivateWindowLeaveModal();
    window.open(url, "_self", "noopener, noreferrer");
}

export function configEntryToLinkInfo(entry:UserguideCardProps){
    let exp = new RegExp("{{(.*?)}}","g")
    let matches = entry.description.match(exp)

    if(!matches || matches.length === 0 || !entry.links){
        return undefined;
    }

    let info = []
    let text = entry.description;
    for (let match of matches) {
        let innerMatch = match.substring(2,match.length-2)
        if(! (innerMatch in entry.links)){
            continue
        }
        let arr = text.split(match)
        text = "";
        for(let i = 1;i<arr.length;i++){
            if(i !== 1){
                text = text + match
            }
            text = text + arr[i]
        }
        info.push({
            text:arr[0],
            url: entry.links[innerMatch]
        })
    }
    info.push({text:text,url:null})
    return info;
}

export const getCurrentDeviceTypeByPlatform = () : string => {
    switch (getCurrentPlatformTrimmedLowercase()) {
        case "windows": return "Windows Hello";
        case "ios":
            return getKnownProductName() || "iPhone";
        case "osx":
            const productName = getKnownProductName(); // this uses the user agent
            if (productName) {
                return productName;
            }

            // No user agent data for product or macbook
            const isPad = navigator.maxTouchPoints && navigator.maxTouchPoints > 2
            return isPad ? "iPad" : "Macbook"
        case "android": return "Android Device";
        default: return "Device";
    }
} 

export const identifyPlatformByDeviceName = (name: string) : authTypes => {
    // if user used our name suggestions, we can identify the platform
    if(!name){
        return "fido-passkey";
    }

    if(name.includes("iPhone") || name.includes("Macbook")) {
        return "apple";
    } else if(name.includes("Windows")) {
        return "windows_hello";
    } else if(name.includes("Android")) {
        return "android";
    } else if(name.includes("Security") || name.includes("Key")) {
        return "fido-passkey";
    } else {
        return "fido-passkey";
    }
}
