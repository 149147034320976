import React, {FunctionComponent, useCallback, useEffect} from "react";
import {useGlobalState} from "./GlobalState";
import {useTranslation} from "react-i18next";
import {ErrorResponse} from "./interfaces/types";
import {Modal} from "designsystem/src/components/Modal/Modal";

export const ErrorModal: FunctionComponent = () => {
  const [error, setError] = useGlobalState("error");
  const {t} = useTranslation();

  // We set focus on the close button, on reason is to prevent accidentally resending forms.
  let closeButton: HTMLButtonElement | null = null;

  const onCloseModal = () => {
    setError(null)
  }

  const handleKeyDown = useCallback((event: { key: string; }) => {
    if (event.key === "Escape") {
      onCloseModal();
    }
  }, [])

  useEffect(() => {
    closeButton?.focus();
    document.addEventListener('keydown', handleKeyDown);
    return () => { document.removeEventListener("keydown", handleKeyDown) };
  }, [handleKeyDown])

  // List of known error messages.
  // If key is missing system will display a generic message and do not lookup for i18n translation.
  const knownErrorMessages = {
    Messages: [
      "Fido2.api.error.strongkey.default",
      "Fido2.api.error.strongkey.timeout",
      "Fido2.api.error.strongkey.invalid.timestampMs",
      "Fido2.api.error.strongkey.invalid.attesttation.format",
      "Fido2.api.error.logical.key.default",
      "Fido2.api.error.logical.key.LogicalKeyAaguidMustBeEqual",
      "Fido2.api.error.logical.key.DuplicatedTokenDisplayName",
      "Fido2.api.error.strongkey.empty.username",
    ],
    default: 'FactorAuthentication.default',
  };

  function memberOfErrorResponse(object: any): object is ErrorResponse {
    if (object) {
      return object.error_code;
    }
    return false;
  }

  const getErrorModalBody = (
      errorToRender: ErrorResponse | string | null,
  ) => {
    let errorString = "<br/>";
    if (errorToRender) {

      if (typeof errorToRender === "object") {

        // add error only if no code has been added yet
        if (errorToRender.message) {
          errorString += getMappedErrorMessage(errorToRender.message);
        }

        if (errorToRender.errorId) {
          errorString += '<br/><br/>' + `(${t('global.errors.FactorAuthentication.trackingid', {errorId: errorToRender.errorId})})`;
        }

        if (errorString.length == 0) {
          const unExpectedError = t('global.errors.FactorAuthentication.unexpected');
          let part1 = JSON.stringify(unExpectedError);
          let part2 = JSON.stringify(errorToRender, null, 2,);
          errorString = `An unexpected error occurred: ${part1} ${part2}`;
        }

      } else {
        errorString = getMappedErrorMessage(errorToRender);
      }
    }
    return errorString;
  };

  const getMappedErrorMessage = (errorToRender: string): string => {
    if (errorToRender.split(" ").length > 1) {
      return errorToRender;
    }

    let errorString = errorToRender;

    if (knownErrorMessages.Messages.includes(errorToRender)) {
      const lookupKey = 'global.errors.' + errorToRender;
      errorString = t(lookupKey.trim());
    }

    return errorString === errorToRender
        ? `${t('global.errors.FactorAuthentication.unknown')} (${errorString}).`
        : errorString;
  }

  return (error !== null ? <Modal
          onClose={onCloseModal}
          children={getErrorModalBody(error)}
          title={t('modal.error.unknown.headline')}
          type="error"
          backgroundLocked={true}
          onSubmit={null}
      />:
      <></>)
}