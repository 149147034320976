import React from 'react';
import {I18NHeadingProps} from "../../../../shared/interfaces";
import {Heading} from "../Heading/Heading";
import {useTranslation} from "react-i18next";

/**
 * Primary UI component for user interaction
 */
export const I18NHeading : React.FC<I18NHeadingProps> = ({id, className, i18n, options, type}) => {
    
    const {t} = useTranslation();
    
    return (
        <Heading id={id} className={className} text={t(i18n, options)} type={type}/>
    );
};
