import React from 'react';
import './navigation.scss';
import {NavigationEntryProps} from "../../../../shared/interfaces";
import {I18NParagraph} from "../I18NParagraph/I18NParagraph";
import { UntitledColorizeIcon } from '../icons';

/**
 * Primary UI component for user interaction
 */
export const NavigationEntry : React.FC<NavigationEntryProps> = (props) => {

  return (
      <button id={props.id} onClick={props.onClick} className={"navigation-entry"}>
        {props.icon ? <UntitledColorizeIcon name={props.icon} />: <></>}
        {
          props.i18nNote ?
              <div className={"navigation-entry-note"}>
                <I18NParagraph i18n={props.i18nLabel} className={"strong"}/>
                <I18NParagraph i18n={props.i18nNote}/>
              </div>
              :
              <I18NParagraph i18n={props.i18nLabel} className={"strong"}/>
        }
        {
          props.children
        }
      </button>
  );
};
