import clsx from "clsx";
import { UntitledColorizeIcon, UntitledIcon } from "../icons";
import { Spacing } from "../Spacing";
import { CardGroupHeaderProps } from "./cardGroupHeader.interface";

import "./cardGroupHeader.scss";

const CardGroupHeader: React.FC<CardGroupHeaderProps> = ({
  title,
  description,
  status = "info",
  noIcon = false,
  icon="face-smile"
}) => (
  <Spacing
    flexDirection="column"
    className="card-group-header"
    alignItems="center"
  >
    {!noIcon && (
      <div className={clsx("icon-wrapper", status)}>
        {status === "info" ? (
          <UntitledColorizeIcon name={icon} />
        ) : (
          <UntitledIcon name={`shield-${status}`} />
        )}
      </div>
    )}
    <div className="title">{title}</div>
    <div className="description">{description}</div>
  </Spacing>
);

export { CardGroupHeader };
