import React from 'react';
import './cpModule.scss';
import {CPModuleProps} from "../../../../shared/interfaces";
import {Button} from "../Button/Button";
import {Icon} from "../Icon/Icon";
import {Heading} from "../Heading/Heading"
import {I18NParagraph} from "../I18NParagraph/I18NParagraph";

/**
 * Primary UI component for user interaction
 */
export const CPModule: React.FC<CPModuleProps> = ({title, subtitleI18n, buttons}) => {

      if (buttons === undefined) {
        return <I18NParagraph i18n={"global.errors.default"}/>;
      }

      return (
          <div className="cp-module--wrapper">
            <div className="cp-module">
              <Icon icon={"cp_onboarding"}/>
              <div className="content">
                <div className="text">
                  <Heading text={title} type={"h4"}/>
                  <I18NParagraph i18n={subtitleI18n}/>
                </div>
                <div className="button-wrapper">
                  <Button
                      onClick={buttons.signIn.onClick}
                      label={buttons.signIn.label}
                      mode={"yellow"}
                      id={buttons.signIn.id}
                      className="cp-button"
                  />
                  <Button
                      onClick={buttons.cantLogin.onClick}
                      label={buttons.cantLogin.label}
                      mode={"white"}
                      id={buttons.cantLogin.id}
                      className="cp-button"
                  />
                </div>
              </div>
            </div>
          </div>
      );
    }
;
