import {createClient} from "react-fetching-library";

export const client = createClient();

export async function httpGet<T>(endpoint: string, cookies: any) {
  return client.query<T>({
    method: "GET",
    headers: createHeaders(cookies),
    endpoint: endpoint,
    responseType: "json",
  });
}

export async function httpPost<T>(endpoint: string, jsonBody: any, cookies: any) {
  return client.query<T>({
    method: "POST",
    headers: createHeaders(cookies),
    body: jsonBody,
    endpoint: endpoint,
    responseType: "json",
  });
}

function createHeaders(cookies: any) {
  const xsrfToken = cookies["XSRF-TOKEN"] || '';
  return {
    "Accept": "application/json",
    "Content-Type": "application/json",
    "X-XSRF-TOKEN": xsrfToken
  };
}
