import React from "react";
import "./deleteLastAuthInfo.scss";
import {Icon} from "../../Icon/Icon";
import {Paragraph} from "../../Paragraph/Paragraph";
import {DeleteLastAuthInfoProps, iconType} from "../../../../../shared/interfaces";
import {I18NParagraph} from "../../I18NParagraph/I18NParagraph";
import {I18NHeading} from "../../I18NHeading/I18NHeading";
import {I18NButton} from "../../I18NButton/I18NButton";

export const DeleteLastAuthInfo : React.FC<DeleteLastAuthInfoProps> = (props) => {
    
    let displayedAuthenticators = ["touchid", "faceid", "fido-passkey", "windows_hello", "android", "other"];
    
    const getIconTypeBeAuthName = (authName : string) : iconType => {
        switch (authName){
            case "apple":
                return "apple";
            case "touchid":
                return "touchid";
            case "faceid":
                return "faceid";
            case "fido-passkey":
                return "fido-passkey";
            case "windows_hello":
                return "windows_hello";
            case "android":
                return "android";
            case "other":
                return "fido-passkey";
        }
        return undefined;
    }
    
    const renderOtherAuthenticators = () => {

        let filtered =  displayedAuthenticators.filter((auth) => auth !== props.authType)

        let row1 = filtered.map((auth, index) => {
            if(index < 3){
                return <Icon key={index} icon={getIconTypeBeAuthName(auth)} className={"icon-size"}/>
            }
            return <></>
        })

        let row2 = filtered.map((auth, index) => {
            if(index >= 3){
                return <Icon key={index} icon={getIconTypeBeAuthName(auth)} className={"icon-size"}/>
            }
            return <></>
        })

        return (<><div>{row1}</div><div>{row2}</div></>)
    }
    
    return (
        <div className={"onlyOneAuthInfo"}>
            <div className={"information"}>
                <I18NParagraph i18n={"authenticationInformation.remove.firstTitle"}/>
            </div>
            <div className={"explanation"}>
                <div className={"expected explanation-card"}>
                    <Icon icon={"success"} className={"icon-size"}/>
                    <I18NHeading i18n={"authenticationInformation.remove.secondTitle"} type={"h3"}/>
                    <div className={"authenticators"}>
                        { renderOtherAuthenticators() }
                    </div>
                    <I18NButton id="last-key-modal-KeepButton" mode={"yellow"} i18n={"authenticationInformation.remove.buttonLabelKeep"} type={"submit"} onClick={props.doRegister}/>
                </div>
                <div className={"plus"}>
                    <Paragraph text={"or"}/>
                </div>
                <div className={"current explanation-card"}>
                    <Icon icon={"warning"} className={"icon-size"}/>
                    <I18NHeading i18n={"authenticationInformation.remove.thirdTitle"} type={"h3"}/>
                    <div className={"authInfo"}>
                        <Paragraph text={props.authName}/>
                        <Icon icon={getIconTypeBeAuthName(props.authType)} className={"icon-size"}/>
                    </div>
                    <I18NButton id="last-key-modal-DeleteButton" i18n={"authenticationInformation.remove.buttonLabelDelete"}  type={"submit"} mode={"red"} border={true} onClick={props.doRemove}/>
                </div>
            </div>
        </div>
    );
};
