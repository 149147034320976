import React, { FunctionComponent } from "react";
import { useFetchUser } from "../components/UserContext";
import { Route, Routes } from "react-router-dom";
import { contextPath } from "../utils/apiUrl";
import { FidoCredentials } from "../pages/FidoCredentials";
import { PasskeysPage } from "../pages/PasskeysPage";
import { AdvancedProtectionPage } from "../pages/AdvacendProtectionPage";
import { ManageAccountsPage } from "../pages/ManageAccounts";

export const AuthRoutes: FunctionComponent = () => {
  const user = useFetchUser();

  return (
    <Routes>
      <Route
        path={`${contextPath}/fidoCredentials/*`}
        element={<>{user && <FidoCredentials user={user} />}</>}
      />
      <Route
        path={`${contextPath}/passkeys/*`}
        element={<>{user && <PasskeysPage user={user} />}</>}
      />
      <Route
        path={`${contextPath}/advanced-protection/*`}
        element={<>{user && <AdvancedProtectionPage user={user} />}</>}
      />
      <Route
        path={`${contextPath}/accounts/*`}
        element={<>{user && <ManageAccountsPage user={user} />}</>}
      />
    </Routes>
  );
};
