import React from 'react';
import {I18NLinkProps} from "../../../../shared/interfaces";
import {Link} from "../Link/Link";
import {useTranslation} from "react-i18next";

/**
 * Primary UI component for user interaction
 */
export const I18NLink : React.FC<I18NLinkProps> = (props) => {

  const {t} = useTranslation();

  return (
      <Link {...props} text={t(props.i18n)}  />
  );
};
