import React, {FunctionComponent, useEffect} from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import {Page} from "../interfaces/types";
import {determineCurrentPage} from "../utils/util";
import {contextPath} from "../utils/apiUrl";
import {LoginPage} from "../pages/LoginPage";
import {Footer} from "designsystem/src/components/Footer/Footer";
import i18next from "i18next";
import {getCompanyDomain} from "credentialservice/src/utils/util";
import {Icon} from "designsystem/src/components/Icon/Icon";
import {ScrollToTop} from "./ScrollToTop";
import {AuthNavigation, NotAuthNavigation} from "../components/AuthNavigation";
import {AuthRoutes} from "./AuthRoutes";

export const RoutingPage: FunctionComponent = () => {

  let currentPage: Page = determineCurrentPage();

  useEffect(() => {
    document.title = currentPage.name;
  });

  const companyDomain = getCompanyDomain();

  return (
      <div className="cs-main-container-wrapper">
        <header>
          <Routes>
            <Route path={`*`} element={<AuthNavigation />} />
            <Route path={`${contextPath}/login`} element={
              <NotAuthNavigation />
            }/>
          </Routes>
        </header>
        <ScrollToTop/>
        <Routes>
          <Route path={"*"} element={<AuthRoutes />} />
          <Route path={contextPath} element={
            <Navigate to={`${contextPath}/login`}/>
          }/>
          <Route path={`${contextPath}/login`} element={
            <div className="portal_routingpage_wrapper">
              <LoginPage/>
            </div>
          }/>
        </Routes>
        <div style={{display: "none"}}>
          <Icon icon={"info"}/>
          <Icon icon={"error"}/>
          <Icon icon={"warning"}/>
          <Icon icon={"success"}/>
          <Icon icon={"cross_purple"}/>
        </div>
        <Footer companyName={companyDomain}
                showGenderInfo={(i18next && i18next.language.startsWith("de"))}
                version={window.globals.portal_version_info}
        />
      </div>
  )
}
