import React, {createContext, FunctionComponent, ReactNode, useContext, useEffect, useState} from 'react'
import {ErrorResponse, UserInfo} from "../interfaces/types";
import {useGlobalState} from "../GlobalState";
import {client} from "../utils/fetch-client";
import {apiPath} from "../utils/apiUrl";

interface UserContext {
  user: UserInfo | undefined;
  setUser: React.Dispatch<UserInfo>;
}

const defaultUserContext: UserContext = {user: undefined, setUser: (u: UserInfo) => u}

const userContext = createContext<UserContext>(defaultUserContext);

const UserContextProvider: FunctionComponent<{ children: ReactNode }> = ({children}) => {

  const [user, setUser] = useState<UserInfo>();

  useEffect(() => {

    console.log("User set.", user);

  }, [user])

  return (<userContext.Provider value={{user, setUser}}>
    {children}
  </userContext.Provider>);
};

function useFetchUser(): UserInfo | undefined {
  const [, setGlobalError] = useGlobalState("error");
  const context = useContext(userContext);
  const [fetchedUser, setFetchedUser] = useState<UserInfo>();

  useEffect(() => {

    const fetchUserData = async () => {

      const {status, error, payload} = await client.query<UserInfo>({
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        endpoint: `${apiPath}/user`,
        responseType: "json",
      });

      if (status === 401) {
        return;
      }

      // Check for any error in response
      if (error) {
        setGlobalError((payload as ErrorResponse | undefined) ?? "REQUEST_FAILED")
        return;
      }

      if (status == 200 && payload) {
        context.setUser(payload);
        setFetchedUser(payload);
      }
    };

    if (!fetchedUser) {
      fetchUserData();
    }

  }, []);

  return fetchedUser;
}

export {userContext, UserContextProvider, useFetchUser};