import {createGlobalState} from "react-hooks-global-state";

const {useGlobalState} = createGlobalState<{ activeTheme: "old" | "new" }>({
  activeTheme: "old"
});

export function useActiveTheme() {
  const [activeTheme, setActiveTheme] = useGlobalState("activeTheme");

  return {
    activeTheme,
    isOldTheme: activeTheme === "old",
    isNewTheme: activeTheme === "new",
    setTheme: (isOld?: boolean) => {
      const theme = isOld ? "old" : "new";
      localStorage.setItem("activeTheme", theme);
      setActiveTheme(theme);
    },
    toggleActiveTheme: () => {
      activeTheme === "old" ? setActiveTheme("new") : setActiveTheme("old")
      localStorage.setItem("activeTheme", activeTheme ? "new" : "old");
    },
    setActiveThemeFromStorage: () => {
      let activeThemeFromLocalStorage = localStorage.getItem("activeTheme");
      if (activeThemeFromLocalStorage && activeThemeFromLocalStorage !== activeTheme) {
        setActiveTheme(activeThemeFromLocalStorage === "old" ? "old" : "new");
      }
    }
  }
}