import clsx from "clsx";
import { SpacingProps } from "./spacing.interface";
import "./spacing.scss";

export const Spacing: React.FC<SpacingProps> = (
  {
    children,
    flexDirection = "row",
    gap = "4",
    justifyContent = "start",
    alignItems = "start",
    flexWrap = "wrap",
    className,
    Comp = "div",
    style,
    fullWidthChildren = true,
    type = undefined,
    ...props
  },
) => (
  <Comp
    type={type}
    style={{
      gap: `${gap}px`,
      justifyContent,
      alignItems,
      flexWrap,
      flexDirection,
      ...style,
    }}
    className={clsx("spacing", {"fullWidthChildren": fullWidthChildren}, className)}
    {...props}
  >
    {children}
  </Comp>
);
