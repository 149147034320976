import {OfflineLoadingProps} from "../../../../shared/interfaces";
import {useTranslation} from "react-i18next";
import {Button} from "../Button/Button";
import React from "react";
import {Paragraph} from "../Paragraph/Paragraph";

/**
 * Primary UI component for user interaction
 */
export const LoadingOffline :React.FC<OfflineLoadingProps> = (props) => {

  const {t} = useTranslation();

  return (
      <div id="loading">
        <div className={"loading-middle"}>
          <Paragraph text={t('global.offlineSpinner')}/>
          <div className={"loading-animation-wrapper"}>
            <div className="loading-animation"/>
          </div>
          <div className="is-pulled-right ButtonGroup">
            {props.onCancel && <Button label={t('button.cancel')} mode={"white"} onClick={props.onCancel}/>}{" "}
            <Button label={t('button.retry')} mode={"yellow"} onClick={props.onSubmit} disabled={!props.onSubmit}/>
          </div>
        </div>
      </div>
  );
};