import React, {useState} from 'react';
import './textInput.scss';
import {TextInputProps} from "../../../../shared/interfaces";
import {I18NParagraph} from "../I18NParagraph/I18NParagraph";
import {Icon} from "../Icon/Icon";
import {useTranslation} from "react-i18next";

/**
 * Primary UI component for user interaction
 */
export const TextInput: React.FC<TextInputProps> = ({
                                                      fieldId,
                                                      error,
                                                      labelText,
                                                      icon,
                                                      disabled,
                                                      className,
                                                      placeholderText,
                                                      name,
                                                      note,
                                                      blurred,
                                                      autofocus,
                                                      value,
                                                      defaultValue,
                                                      onChange,
                                                      onBlur,
                                                      autoComplete
                                                    }) => {

  const {t} = useTranslation();
  const [blur, setBlur] = useState(true)
  const [warning, setWarning] = useState("");

  const checkCapslock = (keyEvent: React.KeyboardEvent<HTMLInputElement>) => {
    if (keyEvent.getModifierState("CapsLock")) {
      setWarning(t("textinput.caps"));
    } else {
      setWarning("");
    }
  }

  const handleIconClick = () => {
    blur ? setBlur(false) : setBlur(true);
  }

  const getErrorAndWarning = () => {
    if (error || warning) {
      return <div className={"textInput-notifications"}>
        {error &&
        <div id={fieldId ? fieldId + "-error" : undefined} className="textInput_message">
          <div className="textInput_corner error"/>
          <I18NParagraph
              className="textInput_text error"
              i18n={error}
          />
        </div>
        }
        {warning &&
        <div id={fieldId ? fieldId + "-warning" : undefined} className="textInput_message">
          <div className="textInput_corner warning"/>
          <I18NParagraph
              className="textInput_text warning"
              i18n={warning}
          />
        </div>
        }
      </div>
    } else {
      return <></>
    }
  }

  return (
      <div className="textInput">
        {labelText &&
        <label className="textInput_label" htmlFor={fieldId}>
          {labelText}
        </label>
        }
        <div className={"input-password-wrapper"}>
          {
            icon &&
            <Icon className={"img-left"} icon={"search"} sizeInPx={16}/>
          }
          <input
              id={fieldId} disabled={disabled} className={['textInput_input', className, icon ? "icon" : ""].join(' ')}
              placeholder={placeholderText} name={name} type={blurred && blur ? "password" : "text"} autoComplete={autoComplete ?? undefined} defaultValue={defaultValue}
              onKeyDown={blurred && blur ? checkCapslock : undefined}
              onChange={(e) => onChange && onChange(e.target.value)} value={value} onBlur={onBlur} autoFocus={autofocus}
          />
          {
            blurred &&
            <Icon className={"img-right"} icon={blur ? "eye_closed" : "eye_open"} sizeInPx={16} onClick={handleIconClick}
                  titleText={blur ? t("textinput.password.hide") : t("textinput.password.show")}/>
          }
        </div>
        {getErrorAndWarning()}
        {note &&
        <I18NParagraph className={"note"} i18n={note} />
        }
      </div>
  );
};
