import { ReactNode } from "react";
import { useActiveTheme } from "./activeTheme";

export const Redesign: React.FC<{ children: ReactNode }> = ({ children }) => (
  <>{children}</>
);

export const New: React.FC<{ children: ReactNode }> = ({ children }) => {
  const {activeTheme} = useActiveTheme();
  return (<>{activeTheme === "new" && children}</>);
}

export const Old: React.FC<{ children: ReactNode }> = ({ children }) => {
  const {activeTheme} = useActiveTheme();
  return (<>{activeTheme === "old" && children}</>)
};