import {Action, ActionExecutionState, ActionResponse, Actions, ActionState, Execution, ExecutionResponse, Factor, QueryParameters, StoredFrontendState} from "../interfaces/types";
import {CURRENT_ACTION, ENTERED_LOGIN_NAME, FACTORS, TRANSACTION_FACTORPOSITION, TRANSACTIONID} from "./consts";
import {useApiPost} from "./apiUtils";
import {useGlobalState} from "../globalState";
import {useLocation, useNavigate} from "react-router-dom";

/**
 * Action definition dictionary
 */
export const actions: Actions = {
    PasswordResetAction: {
        name: "PasswordResetAction",
        actionPath: "/passwordreset",
        actionApiPath: "/passwordreset"
    },
    PasswordResetOwnedControlledAction: {
        name: "PasswordResetOwnedControlledAction",
        actionPath: "/passwordresetownedctrl",
        actionApiPath: "/passwordresetownedctrl"
    },
    FidoRegistrationAction: {
        name: "FidoRegistrationAction",
        actionPath: "/fidoregister",
        actionApiPath: "/fidoregistration"
    },
    FidoRegistrationOwnedControlledAction: {
        name: "FidoRegistrationOwnedControlledAction",
        actionPath: "/fidoregisterownedctrl",
        actionApiPath: "/fidoregistrationownedctrl"
    },
    FidoDeleteAction: {
        name: "FidoDeleteAction",
        actionPath: "/fidodelete",
        actionApiPath: "/fidodelete"
    },
    OnboardingAction: {
        name: "OnboardingAction",
        actionPath: "/onboarding",
        actionApiPath: "/onboarding"
    },
    InitiateRecoveryAction: {
        name: "InitiateRecoveryAction",
        actionPath: "/initiaterecovery",
        actionApiPath: "/initiaterecovery"
    },
    RecoveryAction: {
        name: "RecoveryAction",
        actionPath: "/recovery",
        actionApiPath: "/recovery"
    },
    AzureRegistrationAction: {
        name: "AzureRegistrationAction",
        actionPath: "/azureregistration",
        actionApiPath: "/azureregistration"
    },
    DummyAction: {
        name: "DummyAction",
        actionPath: "/dummyaction",
        actionApiPath: "/dummyaction"
    },
    DetachedAuthenticationAction: {
        name: "DetachedAuthenticationAction",
        actionPath: "/detachedauthentication",
        actionApiPath: "/detachedauthentication"
    },
    CanNotLoginAction: {
        name: "CanNotLoginAction",
        actionPath: "/cannotlogin",
        actionApiPath: "/cannotlogin"
    },
    WasNotMeRecoveryAction: {
        name: "WasNotMeRecoveryAction",
        actionPath: "/wasnotme",
        actionApiPath: "/wasnotme"
    },
    DisableAdvancedProtectionAction: {
        name: "DisableAdvancedProtectionAction",
        actionPath: "/disableAdvancedProtection",
        actionApiPath: "/disableAdvancedProtection"
    },
    None: {
        name: "None",
        actionPath: "/",
        actionApiPath: "/"
    }
}

const executionPaths: { [index: string]: string; } = {
    DummyActionExecution1: "/executeDummy1",
    DummyActionExecution2: "/executeDummy2",
    PasswordResetExecution: "/executePwd",
    PasswordInitialSetExecution: "/executeInitialPwd",
    FidoDeleteExecution: "/executeFidoDelete",
    AzureRegistrationExecution: "/executeAzure",
    ConfirmDetachedAuthenticationExecution: "/executeDetachedAuthentication",
    InitiateRecoveryExecution: "/execute-initiate",
    AccountSelectionExecution: "/executeAccountSelection",
    WasNotMeRecoveryExecution: "/delete-passkey",
    DisableAdvancedProtectionExecution: "/disableAdvancedProtection",
}

export const getExecutionPathForName = (executionName: string | undefined): string => {
    if (executionName === undefined) {
        console.warn("executionName must not be undefined");
        return "";
    }

    const path = executionPaths[executionName];
    if (!path) {
        console.warn("No executionPath found for executionName:", executionName);
        return "";
    }

    return path;
}

export const getClientErrors = () => {
    return ["UserCanNotBeRegistered", "Fido2.registration.credentialCreateInvalidStateError", "Fido2.registration.credentialCreateNotAllowedError"];
}

export const removeAsteriskIfNeeded = (text: string) => {
    if (window.globals.sms_factor_force_intunesOverAzureMfa === 'false') {
        return text.replace('*', '');
    }
    return text;
};

export const navigateToNextActionExecutionAndSubPathByResponse = (action: Action, {executions: executionsFromResponse}: ExecutionResponse, subPath: string | undefined): string => {

    let allCompleted: boolean = true;
    for (const execKey in executionsFromResponse) {
        const exec = executionsFromResponse[execKey];

        if (!exec.completed) {
            // (currentExecution === exec.executionName) {
            allCompleted = false;
        }
    }

    if (allCompleted) {
        return action.actionPath + "/success";
    }

    let path = action.actionPath + "/execute";

    if (subPath) {
        if (subPath.startsWith('/')) {
            path += subPath;
        } else {
            path += "/" + subPath;
        }
    }
    return path;
}

/**
 * Get`s the next path based on current ActionState and ExecutionResponse. If the response does not exists the
 * next path is set to Root
 *
 * @param actionState of the current action context
 * @param response to determine the next execution path
 * @return object containing the next state and path for the navigate()
 */
export const navigateToNextActionExecutionByResponse = (actionState: ActionState, response?: ExecutionResponse): { path: string, nextState: ActionState } => {
    return navigateToNextActionExecutionByActionAndResponse(actionState.action, actionState, response);
}

export const navigateToNextActionExecutionByActionAndResponse = (action: Action, state: any, response?: ExecutionResponse): { path: string, nextState: ActionState } => {
    let path: string = "/";

    if (response) {
        path = navigateToNextActionExecutionAndSubPathByResponse(action, response, undefined);
    }

    return {path, nextState: {...state, ...response}};
}


// Only used after start in factor selection.
export const navigateToNextActionStepByResponse = (response: ActionResponse): string => {
    const subPaths = navigateToNextActionStep(actions[response.actionName], response.factors);
    if (Array.isArray(subPaths)) {
        return subPaths[0] as string; // gets the first sub route of the factor
    }
    return subPaths as string;
}

const factorsRouteMapping: {
    success: (action: Action) => string[];
    default: (action: Action) => string[];
    [key: string]: (action: Action) => string[];
} = {
    // HINT: result subpath position 0 will be handled as default
    MAIL_OTP_TO_COLLEAGUE: (action: Action) => {
        return [`${action.actionPath}/otptocolleague`,
            `${action.actionPath}/verifyotptocolleague`]
    },
    MAIL_OTP_TO_MANAGER: (action: Action) => {
        return [`${action.actionPath}/otptomanager`,
            `${action.actionPath}/verifyotptomanager`]
    },
    SSO_SAML_VERSUM: (action: Action) => {
        return [`${action.actionPath}/sso/saml/request`,
            `${action.actionPath}/sso/saml/response`]
    },
    GENERIC_SMS_OTP: (action: Action) => {
        return [`${action.actionPath}/phone2`,
            `${action.actionPath}/phone2/verification`]
    },
    PI_STATIC_PASSWORD: (action: Action) => {
        return [`${action.actionPath}/pin`,
            `${action.actionPath}/pin?provider=privacyidea`]
    },
    PI_SMS_OTP: (action: Action) => {
        return [`${action.actionPath}/phone`,
            `${action.actionPath}/phone?provider=privacyidea`,
            `${action.actionPath}/phone/verification`]
    },
    PI_MAIL_OTP: (action: Action) => {
        return [
            `${action.actionPath}/email`,
            `${action.actionPath}/email?provider=privacyidea`]
    },
    MA_AUTHENTICATOR_APP_NOTIFICATION: (action: Action) => {
        return [`${action.actionPath}/azure/phone`,
            `${action.actionPath}/azure`]
    },
    MA_AUTHENTICATOR_APP_OTP: (action: Action) => {
        return [`${action.actionPath}/azure/otp/beginauthentication`,
            `${action.actionPath}/azure/otp`,
            `${action.actionPath}/azure`]
    },
    MA_ONE_WAY_SMS: (action: Action) => {
        return [`${action.actionPath}/azure/onewaysms/phonenumbermatching`,
            `${action.actionPath}/azure/onewaysms`,
            `${action.actionPath}/azure`]
    },
    SC_SMS: (action: Action) => {
        return [`${action.actionPath}/sc/sms/spamprotection`,
            `${action.actionPath}/sc/open-channel`,
            `${action.actionPath}/sc/show-confirmation-code`,
            `${action.actionPath}/sc/sms/close-channel`,
            `${action.actionPath}/sc/solve`]
    },
    REFERENCE_KEY: (action: Action) => {
        return [`${action.actionPath}/recovery-referenceKey/solve`]
    },
    RECOVERY_ACCESS_CODE: (action: Action) => {
        return [`${action.actionPath}/recovery-access-code`,
            `${action.actionPath}/recovery-access-code/solve`]
    },
    SC_EMAIL: (action: Action) => {
        return [`${action.actionPath}/sc/email/spamprotection`,
            `${action.actionPath}/sc/open-channel`,
            `${action.actionPath}/sc/show-confirmation-code`,
            `${action.actionPath}/sc/email/close-channel`,
            `${action.actionPath}/sc/solve`]
    },
    SC_MAGIC_LINK: (action: Action) => {
        return [`${action.actionPath}/sc/magiclink/spamprotection`,
            `${action.actionPath}/sc/open-channel`,
            `${action.actionPath}/sc/show-confirmation-code`,
            `${action.actionPath}/sc/magiclink/close-channel`,
            `${action.actionPath}/sc/solve`]
    },
    MA_TWO_WAY_VOICE_MOBILE: (action: Action) => {
        return [`${action.actionPath}/azure/twowayvoicemobile/phonenumbermatching`,
            `${action.actionPath}/azure/twowayvoicemobile`,
            `${action.actionPath}/azure`]
    },
    LDAP_PASSWORD: (action: Action) => {
        return [action.actionPath + "/password"]
    },
    LDAP_PASSWORD_NCC: (action: Action) => {
        return [action.actionPath + "/password"]
    },
    ID_NOW: (action: Action) => {
        return [action.actionPath + "/idnow"]
    },
    FIDO2: (action: Action) => {
        return [action.actionPath + "/fido"]
    },
    FIDO2_USER_VERIFICATION: (action: Action) => {
        return [action.actionPath + "/fido"]
    },
    FIDO2_USER_VERIFICATION_OTHER_DEVICE: (action: Action) => {
        return [action.actionPath + "/fidoDs"]
    },
    FIDO2_UV_WITHOUT_DEVICE_SWITCH: (action: Action) => {
        return [action.actionPath + "/fidoNoDs"]
    },
    TRU_ID_CHECK_V2: (action: Action) => {
        return [`${action.actionPath}/phonecheck`,
            `${action.actionPath}/phonecheck/redirect`,
            `${action.actionPath}/phonecheck/auth`,
            `${action.actionPath}/phonecheck/jump`]
    },
    SSO_SAML_HOME: (action: Action) => {
        return [`${action.actionPath}/sso/saml/request`,
            `${action.actionPath}/sso/saml/response`];
    },
    NOP: (action: Action) => {
        return [action.actionPath + "/nop"]
    },
    WEB_TOKEN: (action: Action) => {
        return [action.actionPath + "/webtoken"]
    },
    success: (action: Action) => [action.actionPath + "/beforeexecute"],
    default: (action: Action) => [action.actionPath, `${action.actionPath}/factorselection`],
};

export const navigateToNextActionStep = (action: Action, factors?: { [key: string]: Factor }): string[] => {

    // tslint:disable-next-line: forin
    for (const factorKey in factors) {
        const factor = factors[factorKey];

        if (!factor.solved) {

            if (factor.type) {
                return factorsRouteMapping[factor.type](action);
            } else {
                return factorsRouteMapping.default(action);
            }
        }
    }

    return Object.keys(factors || {}).length > 0
        ? factorsRouteMapping.success(action)
        : factorsRouteMapping.default(action);
};

/**
 * Gets the current factor step from the ActionState
 * @param factors
 */
export const getCurrentActionStep = ({factors}: ActionState) => {
    return getCurrentStep(factors);
}

const getCurrentStep = (factors?: { [key: string]: Factor }) => {
    // tslint:disable-next-line: forin
    for (const factorKey in factors) {
        const factor = factors[factorKey];

        if (factor.hasOwnProperty('solved') && !factor.solved) {
            return {
                // tslint:disable-next-line: ban
                factorPosition: parseInt(factorKey, 10),
                factor,
            };
        }
    }

    return {
        factorPosition: -1
    };
};

export const getCurrentActionStepPosition = ({factors}: ActionState) => {
    return getCurrentStepPosition(factors);
}

const getCurrentStepPosition = (factors?: { [key: string]: Factor }) => {
    const result = getCurrentStep(factors);
    return result.factorPosition >= 0 ? result.factorPosition + 1 : result.factorPosition;
}

export const useCancelTransaction = (action: Action) => {
    const [, setGlobalError] = useGlobalState("error");
    const postCancel = useApiPost<any>({
        apiPath: `${action.actionApiPath}/cancel`,
        onError: cancelError => {
            console.log("Cancel transaction failed duo to.", cancelError);
            // Do not show error on cancel this will hide the original error.
        }
    });

    return async (transactionId: string, originalError?: any) => {

        if (!action.actionApiPath) {
            console.warn("Called cancel without action api path.");
        } else if (!transactionId) {
            console.warn("Called cancel without transactionId.");
        } else {
            await postCancel({
                transactionId,
            });
        }

        if (originalError) {
            setGlobalError(originalError);
        }
    }
}

export const clearSsoValues = async () => {

    sessionStorage.removeItem(TRANSACTIONID);
    sessionStorage.removeItem(FACTORS);
    sessionStorage.removeItem(CURRENT_ACTION);

    // We delete all storage items possible set if other pages are rendered.
    if (!(new RegExp("/phonecheck", 'i').test(window.location.pathname)) &&
        !(new RegExp("code", 'i').test(window.location.pathname))) {
        localStorage.removeItem(TRANSACTIONID);
        localStorage.removeItem(TRANSACTION_FACTORPOSITION);
        localStorage.removeItem(CURRENT_ACTION);
        localStorage.removeItem(ENTERED_LOGIN_NAME);
    }

    window.globals.saml_response = "";
    window.globals.saml_relayState = "";
};

export const clearRedirectRegisterValues = () => {
    window.globals.register_actionName = undefined;
    window.globals.register_tokenDisplayName = undefined;
    window.globals.design = undefined;
    window.globals.lng = undefined;
    window.globals.register_authenticatorAttachment = undefined;
    window.globals.register_transactionId = undefined;
    window.globals.register_startTime = undefined;
    window.globals.challenge_start_time = undefined;
    window.globals.challenge_expire_time = undefined;
};

export const useNavigateFactorState = (action: Action,) => {

    const [solvedFactorCount, setSolvedFactorCount] = useGlobalState("solvedFactorCount")
    const navigate = useNavigate();
    const location = useLocation();

    return (response: { solved?: boolean, factorAuthenticationChallenge?: ActionResponse }, state?: any) => {
        if (response.solved) {

            const challenge = response.factorAuthenticationChallenge;
            const nState = {...challenge, action, enteredLoginName: location?.state?.enteredLoginName, ...state};

            setSolvedFactorCount(solvedFactorCount + 1);

            const nextPaths = navigateToNextActionStep(nState.action, nState.factors);

            navigate(nextPaths[0] ?? action.actionPath, {state: nState});
        }
    }
};

export const escapeHtml = (unsafe: string) => {
    return unsafe
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#039;");
};

const getCurrentExecutionForExecutionName = (executionName: string, executions?: { [key: string]: Execution }): { position: number, execution: Execution } | undefined => {

    let executionPosition: number = 0;

    for (const executionKey in executions) {
        const exec = executions[executionKey];
        if (exec.executionName === executionName) {
            return {position: executionPosition, execution: exec};
        }
        executionPosition++;
    }

    return undefined;
};

/**
 * Get`s the execution position of the execution within the given ActionState. If the execution is not part of
 * the state the return value will be -1.
 *
 * @param actionState of the context to look for the position of the execution
 */
export const getExecutionPositionForExecution = (actionState: ActionState): number => {
    return getCurrentExecution(actionState)?.position ?? -1;
};

export const getCurrentExecution = (actionState: { currentExecution?: string, executions?: { [key: string]: Execution } }): { position: number, execution: Execution } | undefined => {
    const currentName = actionState.currentExecution;

    if (!currentName) {
        return undefined;
    }

    return getCurrentExecutionForExecutionName(currentName, actionState.executions);
}

export const resetQueryParameters = (setStateCallback: (queryParameters: QueryParameters) => any) => {
    setStateCallback(initEmptyQueryParameters);
};

export const initEmptyQueryParameters = {
    upnToAuthenticate: "",
    upnToSelect: "",
    fidoCredentialId: "",
    returnUrl: undefined
} as QueryParameters;

export const getCurrentFactorPositionFromLocalStorage = () => {
    const factorPositionRaw = localStorage.getItem(TRANSACTION_FACTORPOSITION) ?? "";
    let factorPosition = parseInt(factorPositionRaw);
    if (isNaN(factorPosition)) {
        return -1;
    }
    return factorPosition;
}

export const getCurrentDomainWithUpperCompanyName = () => {
    if (window.location.hostname.search("merckgroup.com") > 0) {
        return "MERCKgroup.com";
    }
    return "EMDgroup.com";
}

export const getCurrentOppositeDomainWithUpperCompanyName = () => {

    if (window.location.hostname.search("merckgroup.com") > 0) {
        return "EMDgroup.com";
    }
    return "MERCKgroup.com";
}

export const getCompanyDomain = () => {
    if (window.location.hostname.search("merckgroup.com") > 0) {
        return "merckgroup.com";
    }
    return "emdgroup.com";
}

export const getCurrentCompanyName = () => {
    if (window.globals.brand.toLowerCase().trim() === "merck") {
        return "Merck";
    }
    if (window.globals.brand.toLowerCase().trim() === "emd") {
        return "EMD";
    }
    console.error("Your company could not be determined. EMD is set as default.");
    return "EMD";
}

export const makeRandomAlphaNumeric: (length: number, characters?: string) => string = (length, characters) => {

    const chars = characters || 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    let result = '';
    for (let i = 0; i < length; i++) {
        result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return result;
}

export const removeDoubleBrackets = (str: string) => {
    while (str.includes("{{") || str.includes("}}")) {
        str = str.replace("{{", "")
        str = str.replace("}}", "")
    }
    return str;
}

export const recoverStateFromGlobals: () => ActionExecutionState | undefined = () => {

  if (window.globals.stateRawData === "") {
    return undefined;
  }

  try {
    const storedState = JSON.parse(window.globals.stateRawData) as StoredFrontendState;
    const action: Action = actions[storedState.actionName ?? actions.None.name];
    return {...storedState, action} as ActionExecutionState;
  } catch (err) {
    console.error("Could not parse raw state data.", window.globals.stateRawData)
  }
  return undefined;
}

export function useActionState(): ActionExecutionState | undefined {
    const location = useLocation();
    return (location?.state as ActionExecutionState) ?? recoverStateFromGlobals();
}
