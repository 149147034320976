import React from 'react';
import './icons.scss';

import { UntitledIcons, UntitledColorizeIcons } from './Icons';
import {
  UntitledIconProps,
  UntitledColorizeIconProps,
  UntitledIconNames,
  UntitledColorizeIconNames,
} from './icon.interface';
import clsx from 'clsx';

const Icon: React.FC<{
  variant: 'colorize' | 'untitled';
  role: 'img' | 'presentation';
  name: UntitledIconNames | UntitledColorizeIconNames;
  title?: string;
  size: number;
  props: any;
}> = ({ size, props, title, variant, name, role }) => {
  return (
    <svg
      fill={variant === 'colorize' ? undefined : 'currentColor'}
      className={clsx('icon', `icon--${variant}`, props.className)}
      viewBox={`0 0 ${size} ${size}`}
      width={size}
      height={size}
      role={role}
      data-testid="icon"
      {...props}
    >
      <title>{title ?? name}</title>
      {variant === 'colorize' ? (UntitledColorizeIcons as any)[name]() : (UntitledIcons as any)[name]()}
    </svg>
  );
};

export const UntitledIcon: React.FC<UntitledIconProps> = ({ role = 'img', name, size = 32, ...props }) => {
  return Icon({ variant: 'untitled', size, props, name, role });
};

export const UntitledColorizeIcon: React.FC<UntitledColorizeIconProps> = ({ role = 'img', name, size = 24, ...props }) => {
  return Icon({ variant: 'colorize', size, props, name, role });
};
