import React from "react";
import {BrowserRouter} from "react-router-dom";

import './Global';
import {App} from "./App";

import {createRoot} from 'react-dom/client';
import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";
import {webappPath} from "./utils/apiUrl";

const root = createRoot(document.getElementById('root') as HTMLElement);

i18n
.use(Backend)
.use(LanguageDetector)
.use(initReactI18next)
.init(
    {
      fallbackLng: "en",
      // have a common namespace used around the full app
      ns: ["translations"],
      defaultNS: "translations",
      backend: {
        // for all available options read the backend's repository readme file
        loadPath: `${webappPath}/assets/text/locale/{{lng}}`,
      },
      debug: false,
      interpolation: {
        escapeValue: false // not needed for react!
      },
      react: {
        transKeepBasicHtmlNodesFor: ['br', 'u', 'strong', 'i', 'p', 'b', 'ul', 'li', 'a']
      }
    }
).then(() =>
        root.render(
            <BrowserRouter>
              <App/>
            </BrowserRouter>
        )
);

