import React from 'react';
import "./loading.scss"
import {Heading} from "../Heading/Heading";
import {I18NParagraph} from "../I18NParagraph/I18NParagraph";
import {LoadingAzureProps} from "../../../../shared/interfaces";
import {List} from "../List/List";
import {Button} from "../Button/Button";
import {useTranslation} from "react-i18next";
import {Modal} from "../Modal/Modal";
import {I18NHeading} from "../I18NHeading/I18NHeading";
import {Paragraph} from "../Paragraph/Paragraph";

/**
 * Primary UI component for user interaction
 */
export const LoadingAzure : React.FC<LoadingAzureProps> = (props) => {

  const {t} = useTranslation();

  switch (props.step) {
    case "loading":
      return (
          <div id="loading">
            <div id="loading-azure-content">
              <div className={"loading-animation-wrapper"}>
                <div className="loading-animation"/>
              </div>
              { ( !props.validEntropyNumber || props.validEntropyNumber === "0" ) &&
                  <Heading text={t('azureappnotificationscreen.generalhint', {lastDigitsOfPhone: props.lastMobileDigits})}
                type={"h3"}/>
              }
              {props.validEntropyNumber && props.validEntropyNumber.length > 0 &&
                  <div className={"valid-entropy-number"}>
                    <I18NHeading i18n={'azureappnotificationscreen.numbermatchinghint'}  type={"h3"}/>
                    <Paragraph text={props.validEntropyNumber} className={"strong azure-validation-number"}/>
                  </div>
              }
              {!props.connected && <div className="not-connected">
                {t('appLoadingScreen.connectionError')}
              </div>}
              <List intro={t('azureappnotificationscreen.hintlist.header')}
                    subitems={[
                        <I18NParagraph key={1} i18n={"azureappnotificationscreen.hintlist.1"}/>, 
                        <I18NParagraph key={2} i18n={"azureappnotificationscreen.hintlist.2"}/>,
                        <I18NParagraph key={3} i18n={"azureappnotificationscreen.hintlist.3"}/>
                    ]}/>
              <Button label={t('button.cancel')} mode={"white"} onClick={props.onCancel}/>
            </div>
          </div>
      );
    case "finished": {
      return (
          <div id="confirm-azure-push-approved">
              <Modal
                  type={"success"}
                  title={t('modal.success.pushApproved.headline')}
                  children= {<I18NParagraph i18n={'modal.success.pushApproved.text'} />}
                  onSubmit={props.onSubmit}
                  onClose={null}
              />
          </div>
      );
    }
  }
};
