import React, {useState} from "react";
import "./authenticatorListEntry.scss";

import {AuthenticatorListEntryProps, iconType} from "../../../../shared/interfaces";
import {useTranslation} from "react-i18next";
import {Icon} from "../Icon/Icon";
import {DeleteLastAuthInfo} from "../Modal/DeleteLastAuthInfo/DeleteLastAuthInfo";
import {I18NParagraph} from "../I18NParagraph/I18NParagraph";
import {Paragraph} from "../Paragraph/Paragraph";
import {I18NHeading} from "../I18NHeading/I18NHeading";
import {Heading} from "../Heading/Heading";
import {I18NButton} from "../I18NButton/I18NButton";
import {TextInput} from "../TextInput/TextInput";
import {Controller, useForm} from "react-hook-form";
import {Link} from "../Link/Link";

interface AuthenticatorFormData {
  tokenDisplayName: string;
}

/**
 * Primary UI component for user interaction
 */
export const AuthenticatorListEntry : React.FC<AuthenticatorListEntryProps> = (props) => {
  const {t} = useTranslation();
  const {handleSubmit,  formState: {errors}, control} = useForm<AuthenticatorFormData>({mode: "onChange"});

  let icon: iconType = "fido-passkey";

  const [active, setActive] = useState(false);
  const [isInEdit, setIsInEdit] = useState(false);

  const modalOnSubmit = () => {
    if (props.onDelete) {
      props.onDelete();
    }
  }

  const deleteEntry = () => {
    if (props.setModal) {
      if (props.isOnlyAuthenticator && props.registerNewAuthAction) {
        props.setModal({
          type: "warning",
          title: t("authenticationInformation.remove.headline"),
          onClose: props.onCloseModal,
          onSubmit: null,
          backgroundLocked: true,
          children: <DeleteLastAuthInfo authName={props.name} authType={props.type} doRegister={props.registerNewAuthAction} doRemove={() => {
            setActive(false);
            modalOnSubmit()
          }}/>,
        })
      } else {
        props.setModal({
          type: "warning",
          title: t("modal.question.deleteAuthenticator.headline"),
          children: <I18NParagraph i18n={"modal.question.deleteAuthenticator.text"}/>,
          onSubmit: () => {
            setActive(false);
            modalOnSubmit()
          },
          onClose: props.onCloseModal,
          backgroundLocked: true,
          submitButtonId: "deleteSubmitButton"
        })
      }
    }
  }

  const toggleAccordion = () => {
    if (isInEdit) {
      setIsInEdit(false);
      setActive(false);
    } else {
      setActive(!active);
    }
  }

  const saveEdit = async (newNameValue: string) => {
    if (props.saveNewName && await props.saveNewName(newNameValue)) {
      setIsInEdit(false)
    }
  }

  const isDuplicatedTokenName = (tokenName: string) => {
    if (props.name === tokenName) {
      return false;
    }

    if (props.authenticatorWithNameExists && props.authenticatorWithNameExists(tokenName)) {
      return duplicatedTokenName;
    }
  };

  const saveEditDiscoverableCredential = async (newNameValue: string) => {
    if (props.setModal) {
      props.setModal({
        type: "warning",
        title: t("modal.question.renameResidentKeyAuthenticator.headline"),
        children: <I18NParagraph i18n={"modal.question.renameResidentKeyAuthenticator.text"}/>,
        onSubmit: () => saveEdit(newNameValue),
        onClose: props.onCloseModal,
        backgroundLocked: true,
        submitButtonLabel: t("modal.question.renameResidentKeyAuthenticator.button.continue"),
        resetButtonLabel: t("modal.question.renameResidentKeyAuthenticator.button.cancel")
      })
    }
  }

  const requiredMessage = t("portal.fidoCredentials.tokenDisplayName.required");
  const maxLength: number = 50;
  const maxLengthMessage = t("portal.fidoCredentials.tokenDisplayName.maxLength", {maxLength});
  const allowedCharacters = t("portal.fidoCredentials.tokenDisplayName.allowedCharacters");
  const duplicatedTokenName = t("portal.fidoCredentials.tokenDisplayName.duplicatedTokenName");

  const onSubmit = handleSubmit(async (form) => {
    const tokenDisplayName = form.tokenDisplayName;
    if (props.createdWithRequireResidentKey) {
      await saveEditDiscoverableCredential(tokenDisplayName);
    } else {
      await saveEdit(tokenDisplayName);
    }
  });

  return (
      <>
        <div className={"authenticatorListEntry"}>
          <div className={"entry-header"}>
            <div className={"entry-header-upper-row"}>
              <Icon icon={icon} className={"entry-icon"} id={props.id ? props.id + "-toggleDetailButton" : undefined} onClick={toggleAccordion}/>
              {isInEdit ?
                    <form onSubmit={onSubmit} className={"authenticatorList_name_edit"}>
                      <Controller name="tokenDisplayName"
                          rules={{
                            required: requiredMessage,
                            pattern: {value: /^[\w\u0020-]+$/i, message: allowedCharacters},
                            maxLength: {value: maxLength, message: maxLengthMessage},
                            validate: {isDuplicatedTokenName}
                          }}
                          defaultValue={props.name}
                          control={control}
                          render={({ field: {onBlur, value, onChange, name}}) =>
                              <TextInput fieldId={props.id ? props.id + "-textField" : undefined}
                                         blurred={false}
                                         error={errors.tokenDisplayName?.message}
                                         onChange={onChange}
                                         onBlur={onBlur}
                                         value={value}
                                         name={name}
                                         autofocus={true}
                              />
                          }
                      />
                    </form>
                    :
                    <div className="name" onClick={toggleAccordion}>
                    <Heading text={props.name ? props.name : ""} type={"h4"} id={props.id ? props.id + "-name" : undefined} className="might-overflow"/>
                    {
                      !(props.recentlyUsed) && !active &&
                      <Icon icon={"warning"} sizeInPx={20}/>
                    }
                    </div>
                }

              <div className="arrow" onClick={toggleAccordion} id={props.id ? props.id + "-toggleDetailButton" : undefined}>
                <div className={["toggle-container", active ? "active" : "inactive"].join(" ")}>
                  <div className={"line top"}/>
                  <div className={"line bottom"}/>
                </div>
              </div>
            </div>
            {
                !props.recentlyUsed && active &&
                <div className="not-in-active-usage-warning" onClick={toggleAccordion}>
                  <Icon icon={"warning"} sizeInPx={20}/>
                  <I18NParagraph i18n={"portal.authenticatorList.warning"}/>
                </div>
            }
            { props.lastUsedDate.valid &&
              <div className={["entry-header-lower-row", active ? "fdc" : "fdr"].join(" ")} onClick={toggleAccordion}>
                <I18NHeading i18n={"portal.authenticatorList.lastUsage"} type={"h4"} className="authenticatorList_content_item_title"/>
                <div className={"imgAndText"}>
                  <I18NParagraph i18n={props.lastUsedDate.i18nKey} values={{value: props.lastUsedDate.i18nValue || ""}} title={props.lastUsedDate.date} className="authenticatorList_content_item_value"/>
                </div>
              </div>
            }
          </div>
          <div className={["authenticatorList_content", active ? "shown" : "hidden"].join(" ")}>
            {   props.deviceType &&
                <div className="authenticatorList_content_item" onClick={toggleAccordion}>
                  <I18NHeading i18n={"portal.authenticatorList.typeOfDevice"} type={"h4"} className="authenticatorList_content_item_title"/>
                  <Paragraph text={props.deviceType} className="authenticatorList_content_item_value"/>
                </div>
            }
            {
                props.creationDate.valid &&
                <div className="authenticatorList_content_item" onClick={toggleAccordion}>
                  <I18NHeading i18n={"portal.authenticatorList.dateOfReg"} type={"h4"} className="authenticatorList_content_item_title"/>
                  <I18NParagraph i18n={props.creationDate.i18nKey} values={{value: props.creationDate.i18nValue || ""}} title={props.creationDate.date} className="authenticatorList_content_item_value"/>
                </div>
            }
            <div className="authenticatorList_content_item" onClick={toggleAccordion}>
              <I18NHeading i18n={"portal.discCredential"} type={"h4"} className="authenticatorList_content_item_title"/>
              <I18NParagraph i18n={props.createdWithRequireResidentKey ? "portal.fidoCredentials.residentKey.yes" : "portal.fidoCredentials.residentKey.no"}
                             className="authenticatorList_content_item_value"/>
            </div>
            <div className="authenticatorList_content_buttons">
              {isInEdit ?
                  <>
                    <I18NButton id={props.id + "-cancelButton"} i18n={"button.cancel"} mode="white" border={true} onClick={() => setIsInEdit(false)}/>
                    <I18NButton id={props.id + "-saveButton"} i18n={"button.save"} mode="white" border={true} onClick={() => onSubmit()}/>
                  </>
                  :
                  <>
                    {props.saveNewName && <I18NButton id={props.id + "-editButton"} i18n={"button.editName"} mode="white" border={true} onClick={() => setIsInEdit(true)}/>}
                    {props.onDelete && <I18NButton id={props.id + "-deleteButton"} i18n={"button.delete"} mode="white" border={true} onClick={deleteEntry} disabled={props.onDeleteDisabled}
                                                   hoverText={props.onDeleteDisabled? t("portal.authenticatorList.infoDeleteLastAuthenticator") : undefined}/>}
                    <I18NButton id={props.id + "-testButton"} i18n={"button.testAuth"} mode="white" border={true} onClick={props.testAuthenticator}
                    />
                  </>

              }
              {props.onSwitchAccount &&
                <I18NParagraph i18n={"portal.authenticatorList.switchAccounts"} >
                  <Link link={undefined} onClick={props.onSwitchAccount}>switch account</Link> to delete or rename
                </I18NParagraph>
              }
            </div>
          </div>
        </div>
      </>
  );
};
