import React from 'react';
import './radioButton.scss';
import {RadioButton_TextArea_Props} from "../../../../shared/interfaces";
import {useTranslation} from "react-i18next";

/**
 * Primary UI component for user interaction
 */
export const TextAreaRadioButton: React.FC<RadioButton_TextArea_Props> = (props) => {

    const {t} = useTranslation();

    return (
        <div className={["radiobutton-wrapper", props.className, props.hide ? "hide" : "", props.recommended ? "highlight" : "no-highlight"].join(" ")}>
            {props.recommended &&
                <div className={"recommended"} onClick={props.onClick}>
                    {t("button.recommended")}
                </div>
            }
            <div className="radiobutton-input-row" onClick={props.onClick}>
                <div className="input">
                    <input id={props.id} type="radio" readOnly
                           checked={props.selected}/>
                </div>
                <div>
                    <label htmlFor={props.id}>
                        {props.label}
                    </label>
                </div>
            </div>
            {
                props.selected &&
                <div className="radiobutton-content-row">
                    <textarea
                        maxLength={props.maxLength}
                        className={"textarea"}
                        name="fido-enforce-disable-text"
                        rows={3}
                        cols={70}
                        onChange={(event) => {
                            props.onTextChange(event.target.value)
                        }}
                        placeholder={t('disableFidoEnforcement.textarea.placeholder')}
                    />
                </div>
            }
        </div>
    )
};
