import { clsx } from "clsx";
import { Button } from "../Button/Button";
import { UntitledColorizeIcon } from "../icons";
import { Spacing } from "../Spacing";
import { AvatarProps } from "./avatar.interface";
import "./avatar.scss";

export const Avatar: React.FC<AvatarProps> = ({
  name,
  description,
  image,
  hasManagedAccounts = false,
  onAddMobileNumber,
  onManageAccounts,
  mobileNumber,
  className,
  onlyImage = false,
  noMobileNumber = false,
  noManageAccounts = false,
  noCard = false,
  ...props
}) => {
  const getInitials = () => {
    return name?.match(/(\b\S)?/g)?.join("");
  };

  return (
    <div
      className={clsx("avatar-card", { "no-card": noCard }, className)}
      tabIndex={0}
      data-testid="avatar-card"
      {...props}
    >
      <Spacing flexDirection="column" gap="12">
        <Spacing justifyContent="space-between">
          {image ? (
            <img src={image} alt={name} className="avatar-image" />
          ) : (
            <span className="initial-avatar">{getInitials()}</span>
          )}
          {!onlyImage && !noManageAccounts && (
            <Button
              mode="link"
              disabled={!hasManagedAccounts}
              style={{
                opacity: hasManagedAccounts ? 1 : 0.5,
                color: hasManagedAccounts
                  ? "var(--button-outline-label-color)"
                  : "var(--text-color-muted)",
              }}
              className="manage-accounts"
              onClick={onManageAccounts}
              label="Manage accounts"
            ></Button>
          )}
        </Spacing>
        {!onlyImage && (
          <Spacing flexDirection="column">
          <span className="name">{name}</span>
          <span className="description">{description}</span>
          {!noMobileNumber &&
            (mobileNumber ? (
              <Spacing fullWidthChildren={false} gap="16" alignItems="center">
                <span className="mobile-number">{mobileNumber}</span>
              <Button
                mode="link"
                icon={<UntitledColorizeIcon name="edit-02" />}
                className="add-mobile-number"
                onClick={onAddMobileNumber}
                label="Edit" />
              </Spacing>
            ) : (
              <Button
                icon={<UntitledColorizeIcon name="phone-01" />}
                mode="link"
                className="add-mobile-number"
                onClick={onAddMobileNumber}
                label="Add mobile number"
              ></Button>
            ))}
        </Spacing>
        )}
      </Spacing>
    </div>
  );
};
