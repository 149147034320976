import React, {FC, useRef, useState} from "react";
import {AuthenticatorListDateProps, AuthenticatorListEntryProps, FidoGroupProps,} from "../../../shared/interfaces";
import {useSwitchAccount} from "../components/AccountSwitchHandler";
import {ErrorResponse, UserInfo} from "../interfaces/types";
import {apiPath, contextPath} from "../utils/apiUrl";
import {AuthenticatorListEntryPropsByUpn, DeleteFidoRequest, FidoDataResponse, FidoRenameRequest,} from "./FidoCredentials";
import {identifyPlatformByDeviceName} from "designsystem/src/utils";
import {useGlobalState} from "../GlobalState";
import {httpGet, httpPost} from "../utils/fetch-client";
import {signInFunction} from "./LoginPage";
import {Avatar} from "designsystem/src/components/Avatar";
import {t} from "i18next";
import {useCookies} from "react-cookie";
import {Spacing} from "designsystem/src/components/Spacing";
import {CardGroup, CardGroupItem,} from "designsystem/src/components/CardGroup/CardGroup";
import {Button} from "designsystem/src/components/Button/Button";
import {Badge} from "designsystem/src/components/Badge/Badge";
import {navigateToAzureMfaRegistration, navigateToFidoDemo, navigateToFidoRegister, navigateToPasswordReset} from "../utils/navigationToFidoApps";

export const ManageAccountsPage: FC<{ user: UserInfo }> = ({ user }) => {
  const switchAccount = useSwitchAccount();

  // oldestAllowedDate is 2019 12 31
  let oldestAllowedDate = new Date(1580477894294);

  const [fidoData, setFidoData] = useState<{
    username: string;
    fastSignInReady: boolean;
    signInRegularly: boolean;
    ownAuthenticators: AuthenticatorListEntryProps[];
    authenticatorListEntryPropsByUpns: AuthenticatorListEntryPropsByUpn[];
    fidoGroupProps?: FidoGroupProps;
  }>({
    username: "",
    fastSignInReady: false,
    signInRegularly: false,
    ownAuthenticators: [],
    authenticatorListEntryPropsByUpns: [],
    fidoGroupProps: undefined,
  });

  const [modal, setModal] = useGlobalState("modal");
  const [, setIsLoading] = useGlobalState("isLoading");
  const [, setGlobalError] = useGlobalState("error");

  const isDateValid = (date: string): boolean => {
    let dateObj = Date.parse(date);

    return !(date === null || dateObj < oldestAllowedDate.getTime());
  };
  const checkForSameDay = (firstDate: Date, secondDate: Date) => {
    return firstDate.getDay() === secondDate.getDay();
  };

  const dateToAgeString = (
    dateString: string,
  ): { key: string; value: string | null } => {
    try {
      // calculate difference in days between currentDate and dateString
      let days = Math.floor(
        (Date.parse(new Date().toISOString()) - Date.parse(dateString)) /
          86400000,
      );
      let sameDay = checkForSameDay(new Date(), new Date(dateString));

      if (days == 0 && sameDay) {
        return { key: "portal.lastSeen.today", value: null };
      } else if (days == 0 && !sameDay) {
        return { key: "portal.lastSeen.yesterday", value: null };
      } else if (days == 1) {
        return { key: "portal.lastSeen.day", value: days.toString() };
      } else if (days <= 13) {
        return { key: "portal.lastSeen.days", value: days.toString() };
      }

      let weeks = Math.floor(days / 7);

      if (weeks <= 5) {
        return { key: "portal.lastSeen.weeks", value: weeks.toString() };
      }

      let months = Math.floor(days / 30);

      if (months == 1) {
        return { key: "portal.lastSeen.month", value: months.toString() };
      }

      return { key: "portal.lastSeen.months", value: months.toString() };
    } catch (e) {
      console.error(e);
    }

    // will end up in dateString being displayed, because it wont be resolved as an i18n key
    return { key: dateString, value: null };
  };

  const validateDateInformation = (dateString: string) => {
    let result: AuthenticatorListDateProps = {
      valid: false,
      i18nKey: "",
      i18nValue: "",
      date: "",
    };
    // check if dateString value is a valid date
    if (isDateValid(dateString)) {
      result.valid = true;
      result.date = dateString;
      let i18nData = dateToAgeString(dateString);
      result.i18nKey = i18nData.key;
      result.i18nValue = i18nData.value || null;
    }
    return result;
  };

  const renameFido = async (logicalKeyId: string, displayName: string) => {
    setModal(undefined);
    setIsLoading({ loadingType: "standard" });
    try {
      const body: FidoRenameRequest = {
        logicalKeyId: logicalKeyId,
        displayName: displayName,
      };
      const { status, error, payload } = await httpPost<any>(
        `${apiPath}/user/fido/displayname`,
        body,
        cookies,
      );

      if (status === 401) {
        signInFunction();
      }

      // Check for any error in response
      if (error) {
        setGlobalError(
          (payload as ErrorResponse | undefined) ?? "REQUEST_FAILED",
        );
        return false;
      }
    } finally {
      setIsLoading({ loadingType: null });
    }

    let res = await fetchFidoData();
    if (res) {
      updateFidoData(res);
    }
    return true;
  };

  const fetchFidoData = async () => {
    setIsLoading({ loadingType: "standard" });
    let res = undefined;
    try {
      const { status, error, payload } = await httpGet<FidoDataResponse>(
        `${apiPath}/user/fido`,
        cookies,
      );

      if (status === 401) {
        signInFunction();
      }

      // Check for any error in response
      if (error) {
        setGlobalError(
          (payload as ErrorResponse | undefined) ?? "REQUEST_FAILED",
        );
        return;
      }

      if (status == 200 && payload) {
        res = payload;
      }
    } finally {
      setIsLoading({ loadingType: null });
    }
    return res;
  };

  const deleteFido = async (logicalKeyId: string) => {
    setModal(undefined);
    setIsLoading({ loadingType: "standard" });
    try {
      const body: DeleteFidoRequest = {
        logicalKeyId: logicalKeyId,
      };
      const { status, error, payload } = await httpPost<any>(
        `${apiPath}/user/fido/delete`,
        body,
        cookies,
      );

      if (status === 401) {
        signInFunction();
      }

      // Check for any error in response
      if (error) {
        setGlobalError(
          (payload as ErrorResponse | undefined) ?? "REQUEST_FAILED",
        );
        return false;
      }
    } finally {
      setIsLoading({ loadingType: null });
    }

    let res = await fetchFidoData();
    if (res) {
      updateFidoData(res);
    }
    return true;
  };

  const sortAuthenticators = (
    authenticators: AuthenticatorListEntryProps[],
  ) => {
    return authenticators.sort((a, b) => {
      let date1 = getYoungerDateOfListEntry(a);
      let date2 = getYoungerDateOfListEntry(b);
      return date2.getTime() - date1.getTime();
    });
  };

  const getYoungerDateOfListEntry = (
    entry: AuthenticatorListEntryProps,
  ): Date => {
    // check if creationDate or lastUsedDate is the younger one
    if (entry.creationDate.valid && !entry.lastUsedDate.valid) {
      return new Date(entry.creationDate.date);
    } else if (!entry.creationDate.valid && entry.lastUsedDate.valid) {
      return new Date(entry.lastUsedDate.date);
    } else if (entry.creationDate.valid && entry.lastUsedDate.valid) {
      let val =
        new Date(entry.creationDate.date).getTime() >
        new Date(entry.lastUsedDate.date).getTime()
          ? entry.creationDate
          : entry.lastUsedDate;
      return new Date(val.date);
    }
    // return old date to put entry at the end of the list (has no effect on displayed information)
    return new Date("2000-01-01");
  };

  const updateFidoData = (data: FidoDataResponse) => {
    const authenticatorListEntryPropsByUpns: AuthenticatorListEntryPropsByUpn[] =
      data.authenticatorsByUpns.map((abu) => {
        const authenticatorListEntryProps: AuthenticatorListEntryProps[] =
          abu.keys.map((f2a) => ({
            type: identifyPlatformByDeviceName(f2a.displayName),
            name: f2a.displayName,
            creationDate: validateDateInformation(f2a.createDate),
            lastUsedDate: validateDateInformation(f2a.lastUsedDate),
            deviceType: f2a.modelDisplayName,
            createdWithRequireResidentKey: f2a.createdWithRequireResidentKey,
            recentlyUsed: f2a.recentlyUsed,
            saveNewName:
              abu.upn === user.preferredUsername
                ? (name: string) => renameFido(f2a.logicalKeyId, name)
                : undefined,
            onDelete:
              abu.upn === user.preferredUsername
                ? () => deleteFido(f2a.logicalKeyId)
                : undefined,
            isOnlyAuthenticator: abu.keys.length === 1,
            onCloseModal: onCloseModal,
            testAuthenticator: () => navigateToFidoDemo(abu.upn),
          }));

        return {
          upn: abu.upn,
          authenticators: sortAuthenticators(authenticatorListEntryProps),
        };
      });
    const [isInFidoMigrationGroupState, setIsInFidoMigrationGroupState] =
      useState<boolean | undefined>(undefined);

    setIsInFidoMigrationGroupState(data?.fidoGroupInfo.isInFidoMigrationGroup);
    setFidoData({
      username: data.username,
      fastSignInReady: data.fastSignInReady,
      signInRegularly: data.signInRegularly,
      ownAuthenticators:
        authenticatorListEntryPropsByUpns.find(
          (v) => v.upn === user.preferredUsername,
        )?.authenticators || [],
      authenticatorListEntryPropsByUpns: authenticatorListEntryPropsByUpns,
      fidoGroupProps: data?.fidoGroupInfo,
    });
  };

  const onCloseModal = () => setModal(undefined);

  const [cookies] = useCookies();
  const xsrfToken = cookies["XSRF-TOKEN"] || "";
  const formRef = useRef<HTMLFormElement>(null);

  return (
    <div className="portal">
      <div className="portal-main-wrapper">
        <div
          className="portal-main-content"
          style={{ alignItems: "flex-start" }}
        >
          <Spacing flexDirection="column">
            <Avatar
              onlyImage
              noCard
              noMobileNumber
              noManageAccounts
              name={user.name}
              description={t(
                "portal.fidoCredentials.account.switch.description",
              )}
            />
            <Spacing alignItems="center" fullWidthChildren={false}>
              <strong>
                {user.name || user.mail.substring(0, user.mail.indexOf("@"))}
              </strong>
              <Badge label="Signed in" status="info" />
            </Spacing>
            <span className="muted">{user.mail}</span>
          </Spacing>
          <hr />
          {user.hasOwnedAccounts && (
            <Spacing flexDirection="column" gap="24">
              <Spacing flexDirection="column" gap="0">
                <strong>
                  {t("portal.fidoCredentials.account.managed.headline")}
                </strong>
                <span className="muted">
                  {t("portal.fidoCredentials.account.managed.name")}
                </span>
              </Spacing>
              <CardGroup>
                <CardGroupItem
                  label={t("button.switchAccount")}
                  icon="switch-horizontal-01"
                  onClick={() => switchAccount()}
                />
                <CardGroupItem
                  label={t("button.resetPassword")}
                  icon="passcode"
                  onClick={() => navigateToPasswordReset(user)}
                />
                <CardGroupItem
                  label={t("button.registerBiometric")}
                  icon="passkey"
                  onClick={() => navigateToFidoRegister(user)}
                />
                <CardGroupItem
                  label={t("button.registerAzureMfa")}
                  onClick={() => navigateToAzureMfaRegistration(user, user.hasOwnedAccounts)}
                />
              </CardGroup>
              <Spacing flexDirection="column">
                <span className="muted">
                  {t("portal.fidoCredentials.account.label")}
                </span>
                <ol style={{ paddingLeft: "16px", margin: 0 }}>
                  {user.ownedAndManagedAccounts.map((account, key) => (
                    <li style={{ paddingTop: "4px", paddingLeft: "4px" }}>
                      {account}
                    </li>
                  ))}
                </ol>
              </Spacing>
            </Spacing>
          )}
          <form
            action={`${contextPath}/logout`}
            method="post"
            ref={formRef}
            style={{ width: "100%" }}
          >
            <input type="hidden" name="_csrf" value={xsrfToken} />
            <Button
              label={t("navigation.logout")}
              style={{ width: "100%", marginTop: "16px" }}
            />
          </form>
        </div>
      </div>
    </div>
  );
};
