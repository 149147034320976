import React from 'react';
import './userCardManaged.scss';
import {UserCardManagedProps} from "../../../../shared/interfaces";
import {Icon} from "../Icon/Icon";
import {Paragraph} from "../Paragraph/Paragraph";
import {I18NButton} from "../I18NButton/I18NButton";
import {I18NHeading} from "../I18NHeading/I18NHeading";
import {I18NParagraph} from "../I18NParagraph/I18NParagraph";

export const UserCardManaged = ({
                                    name,
                                    actionRegisterPersonal,
                                    actionTestPersonal,
                                    actionResetPwPersonal,
                                    actionRegisterManaged,
                                    actionResetPwManaged,
                                    actionLogoutManaged,
                                    actionRegisterAzureMfaPersonal,
                                    actionRegisterAzureMfaManaged
                                }: UserCardManagedProps) => {
    return (
        <div className="managed-usercard-wrapper">
            <div className={"portalUserCard managed"}>
                <I18NHeading i18n={"portal.fidoCredentials.account.personal.headline"} type={"h3"}/>
                <div className={"portalUserCard-userinfo"}>
                    <Icon icon={"user"} sizeInPx={25}/>
                    <Paragraph text={name} className={"portalUserCard-username"}/>
                </div>
                <I18NButton mode={"yellow"} border={true} i18n={"button.registerBiometric"}
                            onClick={actionRegisterPersonal}/>
                <I18NButton mode={"white"} border={true} i18n={"button.testBiometric"} id={"portalTestDevice"}  onClick={actionTestPersonal}/>
                <I18NButton mode={"white"} border={true} i18n={"button.resetPassword"} onClick={actionResetPwPersonal}/>
                <I18NButton mode={"white"} border={true} i18n={"button.registerAzureMfa"}
                            onClick={actionRegisterAzureMfaPersonal}/>
            </div>
            <div className={"portalUserCard"}>
                <I18NHeading i18n={"portal.fidoCredentials.account.managed.headline"} type={"h3"}/>
                <div className={"portalUserCard-userinfo"}>
                    <Icon icon={"users"} sizeInPx={25}/>
                    <I18NParagraph i18n={"portal.fidoCredentials.account.managed.name"}
                                   className={"portalUserCard-username"}/>
                </div>
                <I18NButton mode={"white"} border={true} i18n={"button.registerBiometric"}
                            onClick={actionRegisterManaged}/>
                <I18NButton mode={"white"} border={true} i18n={"button.switchAccount"} onClick={actionLogoutManaged}/>
                <I18NButton mode={"white"} border={true} i18n={"button.resetPassword"} onClick={actionResetPwManaged}/>
                <I18NButton mode={"white"} border={true} i18n={"button.registerAzureMfa"}
                            onClick={actionRegisterAzureMfaManaged}/>
            </div>
        </div>
    )

};
