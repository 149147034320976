import {createGlobalState} from "react-hooks-global-state";
import {ErrorResponse} from "./interfaces/types";
import {LoadingProps, ModalProps} from "../../shared/interfaces";

interface State {
  error: ErrorResponse | string | null;
  isLoading: LoadingProps;
  modal: ModalProps | undefined;
  switchAccount: false | true | string;
}

const initialState: State = 
    {
      error: null, 
      isLoading: {loadingType: null},
      modal: undefined,
      switchAccount: false
    };

export const {useGlobalState} = createGlobalState<State>(initialState);
