import React, {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useCookies} from "react-cookie";
import {useGlobalState} from "../GlobalState";
import {apiPath} from "../utils/apiUrl";
import {ErrorResponse, UserInfo} from "../interfaces/types";
import {httpGet, httpPost} from "../utils/fetch-client";
import {AuthenticatorListDateProps, AuthenticatorListEntryProps, enforceFidoType, FidoGroupProps,} from "../../../shared/interfaces";
import {Modal} from "designsystem/src/components/Modal/Modal";
import {I18NParagraph} from "designsystem/src/components/I18NParagraph/I18NParagraph";
import {I18NHeading} from "designsystem/src/components/I18NHeading/I18NHeading";
import {OnlyOneAuthInfo} from "designsystem/src/components/Modal/OnlyOneAuthInfo/OnlyOneAuthInfo";
import {identifyPlatformByDeviceName} from "designsystem/src/utils";
import {useSwitchAccount} from "../components/AccountSwitchHandler";
import {signInFunction} from "./LoginPage";
import {RadioButtonGroup} from "designsystem/src/components/RadioButtonGroup/RadioButtonGroup";
import {StandardRadioButton} from "designsystem/src/components/RadioButton/StandardRadioButton";
import {TextAreaRadioButton} from "designsystem/src/components/RadioButton/TextAreaRadioButton";
import {Button} from "designsystem/src/components/Button/Button";
import {CardGroup, CardGroupItem,} from "designsystem/src/components/CardGroup/CardGroup";
import {Badge} from "designsystem/src/components/Badge/Badge";
import {Spacing} from "designsystem/src/components/Spacing/Spacing";
import {Paragraph} from "designsystem/src/components/Paragraph/Paragraph";
import {I18NButton} from "designsystem/src/components/I18NButton/I18NButton";
import {DeleteLastAuthInfo} from "designsystem/src/components/Modal/DeleteLastAuthInfo/DeleteLastAuthInfo";

import {Controller, useForm} from "react-hook-form";
import {TextInput} from "designsystem/src/components/TextInput/TextInput";
import {useActiveTheme} from "designsystem/src/components/Redesign/activeTheme";
import {navigateToFidoDemo, navigateToFidoRegister, navigateToPasswordReset} from "../utils/navigationToFidoApps";

export interface FidoDataResponse {
  username: string;
  fastSignInReady: boolean;
  signInRegularly: boolean;
  authenticatorsByUpns: ReadonlyArray<AuthenticatorsByUpn>;
  fidoGroupInfo: FidoGroupProps;
}

export interface FidoRenameRequest {
  logicalKeyId: string;
  displayName: string;
}

export interface DeleteFidoRequest {
  logicalKeyId: string;
}

export interface AuthenticatorsByUpn {
  upn: string;
  keys: ReadonlyArray<Fido2Authenticator>;
}

export interface AuthenticatorListEntryPropsByUpn {
  upn: string;
  authenticators: AuthenticatorListEntryProps[];
}

export interface Fido2Authenticator {
  displayName: string;
  modelDisplayName: string;
  logicalKeyId: string;
  aaguid: string;
  active: boolean;
  createDate: string;
  modifyDate: string;
  lastUsedDate: string;
  createdWithRequireResidentKey: boolean;
  recentlyUsed: boolean;
}

export enum REASON_DISABLE_FIDO_ENFORCE {
  GLOBAL_PROTECT = "Global Protect Pre Logon VPN",
  ENROLL_INTUNE_DEVICE = "Enrollment new Intune device",
  OTHER = "Other",
}

export const PasskeysPage: FunctionComponent<{ user: UserInfo }> = ({
  user,
}) => {
  const [, setGlobalError] = useGlobalState("error");
  const switchAccount = useSwitchAccount();
  const activeTheme = useActiveTheme();

  const { t } = useTranslation(["de", "en"]);
  const [fidoData, setFidoData] = useState<{
    username: string;
    fastSignInReady: boolean;
    signInRegularly: boolean;
    ownAuthenticators: AuthenticatorListEntryProps[];
    authenticatorListEntryPropsByUpns: AuthenticatorListEntryPropsByUpn[];
    fidoGroupProps?: FidoGroupProps;
  }>({
    username: "",
    fastSignInReady: false,
    signInRegularly: false,
    ownAuthenticators: [],
    authenticatorListEntryPropsByUpns: [],
    fidoGroupProps: undefined,
  });

  const [cookies] = useCookies();
  const [, setIsLoading] = useGlobalState("isLoading");
  const [modal, setModal] = useGlobalState("modal");
  const [isInFidoMigrationGroupState, setIsInFidoMigrationGroupState] =
    useState<boolean | undefined>(undefined);
  const [
    activeRadioValueDisableFidoEnforceReason,
    setActiveRadioValueDisableFidoEnforceReason,
  ] = useState<undefined | REASON_DISABLE_FIDO_ENFORCE>(undefined);
  const [
    otherReasonDisableFidoEnforcement,
    setOtherReasonDisableFidoEnforcement,
  ] = useState<string>();

  // oldestAllowedDate is 2019 12 31
  let oldestAllowedDate = new Date(1580477894294);

  //this extra loading indicator is for not showing the full side for a redirect (so it does not look like a reload of the side)
  const [initialLoad, setInitialLoad] = useState(false);

  const fetchFidoData = async () => {
    setIsLoading({ loadingType: "standard" });
    let res = undefined;
    try {
      const { status, error, payload } = await httpGet<FidoDataResponse>(
        `${apiPath}/user/fido`,
        cookies,
      );

      if (status === 401) {
        signInFunction();
      }

      // Check for any error in response
      if (error) {
        setGlobalError(
          (payload as ErrorResponse | undefined) ?? "REQUEST_FAILED",
        );
        return;
      }

      if (status == 200 && payload) {
        res = payload;
      }
    } finally {
      setIsLoading({ loadingType: null });
    }
    return res;
  };

  const updateFidoData = (data: FidoDataResponse) => {
    const authenticatorListEntryPropsByUpns: AuthenticatorListEntryPropsByUpn[] =
      data.authenticatorsByUpns.map((abu) => {
        const authenticatorListEntryProps: AuthenticatorListEntryProps[] =
          abu.keys.map((f2a) => ({
            type: identifyPlatformByDeviceName(f2a.displayName),
            name: f2a.displayName,
            creationDate: validateDateInformation(f2a.createDate),
            lastUsedDate: validateDateInformation(f2a.lastUsedDate),
            deviceType: f2a.modelDisplayName,
            createdWithRequireResidentKey: f2a.createdWithRequireResidentKey,
            recentlyUsed: f2a.recentlyUsed,
            saveNewName:
              abu.upn === user.preferredUsername
                ? (name: string) => renameFido(f2a.logicalKeyId, name)
                : undefined,
            onDelete:
              abu.upn === user.preferredUsername
                ? () => deleteFido(f2a.logicalKeyId)
                : undefined,
            isOnlyAuthenticator: abu.keys.length === 1,
            onCloseModal: onCloseModal,
            testAuthenticator: () => navigateToFidoDemo(abu.upn),
          }));

        return {
          upn: abu.upn,
          authenticators: sortAuthenticators(authenticatorListEntryProps),
        };
      });

    setIsInFidoMigrationGroupState(data?.fidoGroupInfo.isInFidoMigrationGroup);
    setFidoData({
      username: data.username,
      fastSignInReady: data.fastSignInReady,
      signInRegularly: data.signInRegularly,
      ownAuthenticators:
        authenticatorListEntryPropsByUpns.find(
          (v) => v.upn === user.preferredUsername,
        )?.authenticators || [],
      authenticatorListEntryPropsByUpns: authenticatorListEntryPropsByUpns,
      fidoGroupProps: data?.fidoGroupInfo,
    });
  };

  const sortAuthenticators = (
    authenticators: AuthenticatorListEntryProps[],
  ) => {
    return authenticators.sort((a, b) => {
      let date1 = getYoungerDateOfListEntry(a);
      let date2 = getYoungerDateOfListEntry(b);
      return date2.getTime() - date1.getTime();
    });
  };

  const getYoungerDateOfListEntry = (
    entry: AuthenticatorListEntryProps,
  ): Date => {
    // check if creationDate or lastUsedDate is the younger one
    if (entry.creationDate.valid && !entry.lastUsedDate.valid) {
      return new Date(entry.creationDate.date);
    } else if (!entry.creationDate.valid && entry.lastUsedDate.valid) {
      return new Date(entry.lastUsedDate.date);
    } else if (entry.creationDate.valid && entry.lastUsedDate.valid) {
      let val =
        new Date(entry.creationDate.date).getTime() >
        new Date(entry.lastUsedDate.date).getTime()
          ? entry.creationDate
          : entry.lastUsedDate;
      return new Date(val.date);
    }
    // return old date to put entry at the end of the list (has no effect on displayed information)
    return new Date("2000-01-01");
  };

  const validateDateInformation = (dateString: string) => {
    let result: AuthenticatorListDateProps = {
      valid: false,
      i18nKey: "",
      i18nValue: "",
      date: "",
    };
    // check if dateString value is a valid date
    if (isDateValid(dateString)) {
      result.valid = true;
      result.date = dateString;
      let i18nData = dateToAgeString(dateString);
      result.i18nKey = i18nData.key;
      result.i18nValue = i18nData.value || null;
    }
    return result;
  };

  const dateToAgeString = (
    dateString: string,
  ): { key: string; value: string | null } => {
    try {
      // calculate difference in days between currentDate and dateString
      let days = Math.floor(
        (Date.parse(new Date().toISOString()) - Date.parse(dateString)) /
          86400000,
      );
      let sameDay = checkForSameDay(new Date(), new Date(dateString));

      if (days == 0 && sameDay) {
        return { key: "portal.lastSeen.today", value: null };
      } else if (days == 0 && !sameDay) {
        return { key: "portal.lastSeen.yesterday", value: null };
      } else if (days == 1) {
        return { key: "portal.lastSeen.day", value: days.toString() };
      } else if (days <= 13) {
        return { key: "portal.lastSeen.days", value: days.toString() };
      }

      let weeks = Math.floor(days / 7);

      if (weeks <= 5) {
        return { key: "portal.lastSeen.weeks", value: weeks.toString() };
      }

      let months = Math.floor(days / 30);

      if (months == 1) {
        return { key: "portal.lastSeen.month", value: months.toString() };
      }

      return { key: "portal.lastSeen.months", value: months.toString() };
    } catch (e) {
      console.error(e);
    }

    // will end up in dateString being displayed, because it wont be resolved as an i18n key
    return { key: dateString, value: null };
  };

  const checkForSameDay = (firstDate: Date, secondDate: Date) => {
    return firstDate.getDay() === secondDate.getDay();
  };

  const onCloseModal = () => setModal(undefined);

  useEffect(() => {
    const initialLoad = async () => {
      const data = await fetchFidoData();
      const ownAuthenticators = data?.authenticatorsByUpns?.find(
        (v) => v.upn === user.preferredUsername,
      )?.keys;
      if (ownAuthenticators?.length === 0) {
        // skip portal, directly go to fido registration for own account
        navigateToFidoRegister(user, false);
      } else {
        setInitialLoad(true);
        if (data) {
          updateFidoData(data);
          if (ownAuthenticators?.length === 1 && activeTheme.isOldTheme) {
            setModal({
              type: "info",
              title: t("authenticationInformation.addAnother.headline"),
              children: (
                <OnlyOneAuthInfo
                  authType={identifyPlatformByDeviceName(
                    ownAuthenticators[0].modelDisplayName,
                  )}
                  authName={ownAuthenticators[0].displayName}
                />
              ),
              submitButtonLabel: t(
                "authenticationInformation.addAnother.addButton",
              ),
              onSubmit: () => navigateToFidoRegister(user, false), // we navigate to the normal fido registration page because the user has not enough own authenticators
              resetButtonLabel: t("button.close"),
              onClose: onCloseModal,
              backgroundLocked: true,
              submitButtonId: "authenticationInformation-add-another-submit",
              cancelButtonId: "authenticationInformation-add-another-cancel",
            });
          }
        }
      }
    };

    initialLoad();
  }, []);

  useEffect(() => {
    if (activeRadioValueDisableFidoEnforceReason !== undefined) {
      showModalDisableFidoEnforcement();
    }
  }, [
    activeRadioValueDisableFidoEnforceReason,
    otherReasonDisableFidoEnforcement,
  ]);

  const renameFido = async (logicalKeyId: string, displayName: string) => {
    setModal(undefined);
    setIsLoading({ loadingType: "standard" });
    try {
      const body: FidoRenameRequest = {
        logicalKeyId: logicalKeyId,
        displayName: displayName,
      };
      const { status, error, payload } = await httpPost<any>(
        `${apiPath}/user/fido/displayname`,
        body,
        cookies,
      );

      if (status === 401) {
        signInFunction();
      }

      // Check for any error in response
      if (error) {
        setGlobalError(
          (payload as ErrorResponse | undefined) ?? "REQUEST_FAILED",
        );
        return false;
      }
    } finally {
      setIsLoading({ loadingType: null });
    }

    let res = await fetchFidoData();
    if (res) {
      updateFidoData(res);
    }
    return true;
  };

  const deleteFido = async (logicalKeyId: string) => {
    setModal(undefined);
    setIsLoading({ loadingType: "standard" });
    try {
      const body: DeleteFidoRequest = {
        logicalKeyId: logicalKeyId,
      };
      const { status, error, payload } = await httpPost<any>(
        `${apiPath}/user/fido/delete`,
        body,
        cookies,
      );

      if (status === 401) {
        signInFunction();
      }

      // Check for any error in response
      if (error) {
        setGlobalError(
          (payload as ErrorResponse | undefined) ?? "REQUEST_FAILED",
        );
        return false;
      }
    } finally {
      setIsLoading({ loadingType: null });
    }

    let res = await fetchFidoData();
    if (res) {
      updateFidoData(res);
    }
    return true;
  };

  const enforceFido = async (
    enforceFidoValue: enforceFidoType,
    reason: string,
  ) => {
    setIsLoading({ loadingType: "standard" });
    try {
      const { status, error, payload } = await httpPost<any>(
        `${apiPath}/user/fido/enforce/${enforceFidoValue}`,
        { reason: reason },
        cookies,
      );

      if (status === 401) {
        signInFunction();
      }

      // Check for any error in response
      if (error) {
        setGlobalError(
          (payload as ErrorResponse | undefined) ?? "REQUEST_FAILED",
        );
        return false;
      }
    } finally {
      setIsInFidoMigrationGroupState(!isInFidoMigrationGroupState);
      setIsLoading({ loadingType: null });
    }

    let res = await fetchFidoData();
    if (res) {
      updateFidoData(res);
    }
    return true;
  };

  const disableFidoEnforcementReason = () => {
    return (
      <>
        <RadioButtonGroup className={"no-background"}>
          <I18NParagraph i18n={"disableFidoEnforcement.reason.headline"} />
          <StandardRadioButton
            className={"no-background"}
            label={t("disableFidoEnforcement.reason.globalProtect")}
            selected={
              activeRadioValueDisableFidoEnforceReason ===
              REASON_DISABLE_FIDO_ENFORCE.GLOBAL_PROTECT
            }
            onClick={() => {
              setActiveRadioValueDisableFidoEnforceReason(
                REASON_DISABLE_FIDO_ENFORCE.GLOBAL_PROTECT,
              );
              setOtherReasonDisableFidoEnforcement(undefined);
            }}
          />
          <StandardRadioButton
            className={"no-background"}
            label={t("disableFidoEnforcement.reason.newIntuneDevice")}
            selected={
              activeRadioValueDisableFidoEnforceReason ===
              REASON_DISABLE_FIDO_ENFORCE.ENROLL_INTUNE_DEVICE
            }
            onClick={() => {
              setActiveRadioValueDisableFidoEnforceReason(
                REASON_DISABLE_FIDO_ENFORCE.ENROLL_INTUNE_DEVICE,
              );
              setOtherReasonDisableFidoEnforcement(undefined);
            }}
          />
          <TextAreaRadioButton
            className={"no-background"}
            label={t("disableFidoEnforcement.reason.other")}
            selected={
              activeRadioValueDisableFidoEnforceReason ===
              REASON_DISABLE_FIDO_ENFORCE.OTHER
            }
            maxLength={200}
            onClick={() => {
              setActiveRadioValueDisableFidoEnforceReason(
                REASON_DISABLE_FIDO_ENFORCE.OTHER,
              );
            }}
            onTextChange={(prevOtherReason: string) =>
              setOtherReasonDisableFidoEnforcement(prevOtherReason)
            }
          />
        </RadioButtonGroup>
      </>
    );
  };

  const isDateValid = (date: string): boolean => {
    let dateObj = Date.parse(date);

    return !(date === null || dateObj < oldestAllowedDate.getTime());
  };
  const getFidoEnforcementReason = (): string => {
    switch (activeRadioValueDisableFidoEnforceReason) {
      case REASON_DISABLE_FIDO_ENFORCE.GLOBAL_PROTECT:
        return REASON_DISABLE_FIDO_ENFORCE.GLOBAL_PROTECT.toString();
      case REASON_DISABLE_FIDO_ENFORCE.ENROLL_INTUNE_DEVICE:
        return REASON_DISABLE_FIDO_ENFORCE.ENROLL_INTUNE_DEVICE.toString();
      case REASON_DISABLE_FIDO_ENFORCE.OTHER:
        return otherReasonDisableFidoEnforcement ?? "";
    }
    return "None";
  };

  const showModalDisableFidoEnforcement = () => {
    setModal({
      type: "warning",
      onSubmit: () => {
        // Continue anyway we deactivate FIDO enforcement for the user
        enforceFido("disable", getFidoEnforcementReason());
        setModal(undefined);
      },
      onMagicBtn: () => {
        // Reset users own password first
        navigateToPasswordReset(user, false);
        setModal(undefined);
      },
      onClose: () => {
        setModal(undefined);
        setOtherReasonDisableFidoEnforcement(undefined);
        setActiveRadioValueDisableFidoEnforceReason(undefined);
      },

      title: t("portal.warning.fidoEnforcePasswordMayExpired.title"),
      children: (
        <>
          <I18NParagraph
            i18n={"portal.warning.fidoEnforcePasswordMayExpired.subtitle"}
          />
          {disableFidoEnforcementReason()}
        </>
      ),
      submitButtonLabel: t(
        "portal.warning.fidoEnforcePasswordMayExpired.button.continue",
      ),
      magicButtonLabel: t(
        "portal.warning.fidoEnforcePasswordMayExpired.button.resetPwd",
      ),
      submitButtonDisabled: !(
        (activeRadioValueDisableFidoEnforceReason ===
          REASON_DISABLE_FIDO_ENFORCE.OTHER &&
          (otherReasonDisableFidoEnforcement ?? "").trim().length > 0) ||
        activeRadioValueDisableFidoEnforceReason ===
          REASON_DISABLE_FIDO_ENFORCE.GLOBAL_PROTECT ||
        activeRadioValueDisableFidoEnforceReason ===
          REASON_DISABLE_FIDO_ENFORCE.ENROLL_INTUNE_DEVICE
      ),
    });
  };

  const [isInEdit, setIsInEdit] = useState(false);

  const deleteEntry = (auth: AuthenticatorListEntryProps) => {
    if (!setModal) return;
    if (auth.isOnlyAuthenticator && auth.registerNewAuthAction) {
      setModal({
        type: "warning",
        title: t("authenticationInformation.remove.headline"),
        onClose: auth.onCloseModal,
        onSubmit: null,
        backgroundLocked: true,
        children: (
          <DeleteLastAuthInfo
            authName={auth.name}
            authType={auth.type}
            doRegister={auth.registerNewAuthAction}
            doRemove={() => {
              auth.onDelete && auth.onDelete();
            }}
          />
        ),
      });
    } else {
      setModal({
        type: "warning",
        title: t("modal.question.deleteAuthenticator.headline"),
        children: (
          <I18NParagraph i18n={"modal.question.deleteAuthenticator.text"} />
        ),
        onSubmit: () => {
          auth.onDelete && auth.onDelete();
        },
        onClose: auth.onCloseModal,
        backgroundLocked: true,
        submitButtonId: "deleteSubmitButton",
      });
    }
  };

  const requiredMessage = t("portal.fidoCredentials.tokenDisplayName.required");
  const maxLength: number = 50;
  const maxLengthMessage = t(
    "portal.fidoCredentials.tokenDisplayName.maxLength",
    { maxLength },
  );
  const allowedCharacters = t(
    "portal.fidoCredentials.tokenDisplayName.allowedCharacters",
  );
  const duplicatedTokenName = t(
    "portal.fidoCredentials.tokenDisplayName.duplicatedTokenName",
  );

  const authList = fidoData.authenticatorListEntryPropsByUpns.filter(
    (v) => v.authenticators.length > 0 || v.upn === user.preferredUsername,
  );
  const authenticators = authList.map((v) => v.authenticators).flat();

  const [currentEditButtonName, setCurrentEditButtonName] =
    useState<string>("");

  const isDuplicatedTokenName = (tokenName: string) => {
    const auth = authenticators.find((v) => v.name === currentEditButtonName);
    if (auth?.name === tokenName) {
      return false;
    }

    if (
      auth?.authenticatorWithNameExists &&
      auth.authenticatorWithNameExists(tokenName)
    ) {
      return duplicatedTokenName;
    }
  };

  const saveEdit = async (
    newNameValue: string,
    auth: AuthenticatorListEntryProps,
  ) => {
    if (auth?.saveNewName && (await auth.saveNewName(newNameValue))) {
      setCurrentEditButtonName("");
      setIsInEdit(false);
    }
  };

  const saveEditDiscoverableCredential = async (
    newNameValue: string,
    auth: AuthenticatorListEntryProps,
  ) => {
    setModal({
      type: "warning",
      title: t("modal.question.renameResidentKeyAuthenticator.headline"),
      children: (
        <I18NParagraph
          i18n={"modal.question.renameResidentKeyAuthenticator.text"}
        />
      ),
      onSubmit: () => {
        saveEdit(newNameValue, auth);
        setCurrentEditButtonName("");
      },
      onClose: () => {
        auth.onCloseModal;
        setCurrentEditButtonName("");
        setIsInEdit(false);
      },
      backgroundLocked: true,
      submitButtonLabel: t(
        "modal.question.renameResidentKeyAuthenticator.button.continue",
      ),
      resetButtonLabel: t(
        "modal.question.renameResidentKeyAuthenticator.button.cancel",
      ),
    });
  };

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<{ tokenDisplayName: string }>({ mode: "onChange" });

  const onSubmit = handleSubmit(async (form: any) => {
    const auth = authenticators.find((v) => v.name === currentEditButtonName);
    const tokenDisplayName = form.tokenDisplayName;

    if (!auth) return;

    if (auth?.createdWithRequireResidentKey) {
      await saveEditDiscoverableCredential(tokenDisplayName, auth);
    } else {
      await saveEdit(tokenDisplayName, auth);
    }
  });

  return (
    <div className={"portal"}>
      <div className="portal-main-wrapper">
        <div className="portal-main-content">
          <div className="portal-column">
            {initialLoad && (
              <div className="portal-column-item">
                <I18NHeading id="portalDeviceHeader" i18n={"portal.yourAuthenticators"} type={"h2"} />
                <p className="muted">
                 {t("portal.authenticatorDescription")}
                </p>
                <Spacing justifyContent="end">
                  <Button
                    label={"Add new"}
                    mode={"yellow"}
                    id="fidoAuthenticatorsEntry-add"
                    onClick={() => navigateToFidoRegister(user)}
                    style={{ flexGrow: "inherit" }}
                    />
                </Spacing>
                <p>
                    {t("portal.registeredPasskeys")}
                </p>
                <CardGroup>
                  {fidoData.authenticatorListEntryPropsByUpns
                    .filter(
                      (v) =>
                        v.authenticators.length > 0 ||
                        v.upn === user.preferredUsername,
                    )
                    .map((v, i) => (
                      <>
                        <CardGroupItem id="authenticatorList" label={v.upn} className="parent" />
                        {v.authenticators.map((auth, i) => {
                          return (
                            <CardGroupItem
                              id={"fidoAuthenticatorsEntry-" + i + "-name"}
                              key={i}
                              icon="phone-01"
                              label={auth.name}
                              description={auth.deviceType}
                              badge={
                                !auth.recentlyUsed && (
                                  <Badge
                                    label={t(
                                      "portal.authenticatorList.warning",
                                    )}
                                    status="warning"
                                  />
                                )
                              }
                            >
                              <Spacing gap="24">
                                {isInEdit &&
                                  currentEditButtonName === auth.name && (
                                    <form
                                      onSubmit={onSubmit}
                                      className={"authenticatorList_name_edit"}
                                    >
                                      <Controller
                                        name="tokenDisplayName"
                                        rules={{
                                          required: requiredMessage,
                                          pattern: {
                                            value: /^[\w\u0020-]+$/i,
                                            message: allowedCharacters,
                                          },
                                          maxLength: {
                                            value: maxLength,
                                            message: maxLengthMessage,
                                          },
                                          validate: { isDuplicatedTokenName },
                                        }}
                                        defaultValue={auth.name}
                                        control={control}
                                        render={({
                                          field: {
                                            onBlur,
                                            value,
                                            onChange,
                                            name,
                                          },
                                        }) => (
                                          <TextInput
                                            fieldId={"fidoAuthenticatorsEntry-" + i + "-edit"}
                                            blurred={false}
                                            error={
                                              errors.tokenDisplayName?.message
                                            }
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                            name={name}
                                            autofocus={true}
                                          />
                                        )}
                                      />
                                    </form>
                                  )}
                                <Spacing gap="12">
                                  <ul>
                                    {auth.lastUsedDate.valid && (
                                      <li>
                                        <I18NHeading
                                          i18n={
                                            "portal.authenticatorList.lastUsage"
                                          }
                                          type={"h4"}
                                          className="authenticatorList_content_item_title"
                                        />
                                        <span className={"imgAndText"}>
                                          <I18NParagraph
                                            i18n={auth.lastUsedDate.i18nKey}
                                            values={{
                                              value:
                                                auth.lastUsedDate.i18nValue ||
                                                "",
                                            }}
                                            title={auth.lastUsedDate.date}
                                            className="authenticatorList_content_item_value"
                                          />
                                        </span>
                                      </li>
                                    )}

                                    {auth.deviceType && (
                                      <li>
                                        <I18NHeading
                                          i18n={
                                            "portal.authenticatorList.typeOfDevice"
                                          }
                                          type={"h4"}
                                          className="authenticatorList_content_item_title"
                                        />
                                        <Paragraph
                                          text={auth.deviceType as any}
                                          className="authenticatorList_content_item_value"
                                        />
                                      </li>
                                    )}
                                    <li>
                                      <strong>
                                        <I18NHeading
                                          i18n={
                                            "portal.authenticatorList.dateOfReg"
                                          }
                                          type={"h4"}
                                          className="authenticatorList_content_item_title"
                                        />
                                      </strong>
                                      <I18NParagraph
                                        i18n={auth.creationDate.i18nKey}
                                        values={{
                                          value:
                                            auth.creationDate.i18nValue || "",
                                        }}
                                        title={auth.creationDate.date}
                                        className="authenticatorList_content_item_value"
                                      />
                                    </li>
                                    <li>
                                      <strong>
                                        <I18NHeading
                                          i18n={"portal.discCredential"}
                                          type={"h4"}
                                          className="authenticatorList_content_item_title"
                                        />
                                      </strong>
                                      <I18NParagraph
                                        i18n={
                                          auth.createdWithRequireResidentKey
                                            ? "portal.fidoCredentials.residentKey.yes"
                                            : "portal.fidoCredentials.residentKey.no"
                                        }
                                        className="authenticatorList_content_item_value"
                                      />
                                    </li>
                                  </ul>
                                  <Spacing
                                    flexWrap="nowrap"
                                    justifyContent="end"
                                    gap="8"
                                    fullWidthChildren={false}
                                  >
                                    {isInEdit &&
                                      currentEditButtonName === auth.name && (
                                        <>
                                          <I18NButton
                                            id={"fidoAuthenticatorsEntry-" + i + "-cancelButton"}
                                            i18n={"button.cancel"}
                                            mode="white"
                                            border={true}
                                            onClick={() => setIsInEdit(false)}
                                          />
                                          <I18NButton
                                            id={"fidoAuthenticatorsEntry-" + i + "-saveButton"}
                                            i18n={"button.save"}
                                            mode="white"
                                            border={true}
                                            onClick={() => onSubmit()}
                                          />
                                        </>
                                      )}
                                    {!isInEdit && auth.saveNewName && (
                                      <I18NButton
                                        id={"fidoAuthenticatorsEntry-" + i + "-editButton"}
                                        i18n={"button.editName"}
                                        mode="white"
                                        border={true}
                                        onClick={() => {
                                          setCurrentEditButtonName(auth.name);
                                          setIsInEdit(true);
                                        }}
                                      />
                                    )}
                                    {!isInEdit && auth.onDelete && (
                                      <I18NButton
                                        mode="red"
                                        id={"fidoAuthenticatorsEntry-" + i + "-deleteButton"}
                                        i18n={"button.delete"}
                                        border
                                        onClick={() => deleteEntry(auth) as any}
                                        disabled={auth.onDeleteDisabled}
                                      />
                                    )}
                                  </Spacing>
                                </Spacing>
                              </Spacing>
                            </CardGroupItem>
                          );
                        })}
                      </>
                    ))}
                </CardGroup>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="mainWrapper">
        {modal && (
          <Modal
            type={modal.type}
            onSubmit={modal.onSubmit}
            title={modal.title}
            children={modal.children}
            submitButtonLabel={modal.submitButtonLabel}
            resetButtonLabel={modal.resetButtonLabel}
            onClose={modal.onClose}
            backgroundLocked={true}
            submitButtonId={modal.submitButtonId}
            cancelButtonId={modal.cancelButtonId}
            onMagicBtn={modal.onMagicBtn}
            magicButtonId={modal.magicButtonId}
            magicButtonLabel={modal.magicButtonLabel}
            submitButtonDisabled={modal.submitButtonDisabled}
          />
        )}
      </div>
    </div>
  );
};
