import {createGlobalState} from "react-hooks-global-state";
import {BaseError, ClientError, ErrorResponse, FactorChallengeResponse, QueryParameters, ServerErrorResponse} from "./interfaces/types";
import {LoadingProps, ModalProps} from "../../shared/interfaces";
import {ReturnUrlValidator} from "./utils/ReturnUrlValidator";

interface State {
	traceId: string;
	error: ServerErrorResponse | ErrorResponse | ClientError | BaseError | string | null;
	failedFactorChallengeResponse: FactorChallengeResponse | null | undefined;
	isLoading: LoadingProps;
	factorsCount : number,
	solvedFactorCount : number,
	modal: ModalProps | undefined;
	queryParameters: QueryParameters,
	invalid: boolean,
	returnUrl: string | undefined,
}


const params = new URLSearchParams(location.search);
const getQueryParameters = (): QueryParameters => {
	
	const detachedParams = params.get("detachedParams");
	if (detachedParams) {
		const decodedParams = atob(detachedParams);
		const parsedParams = JSON.parse(decodedParams);
		const {factor, ...rest} = parsedParams;
		return {factorCombination: [factor], ...rest};
	} else {
		return {
			upnToAuthenticate: params.get("upnToAuthenticate") ?? "",
			upnToSelect: params.get("upnToSelect") ?? "",
			fidoCredentialId: params.get("fidoCredentialId") ?? "",
			publicToken: params.get("publicToken") ?? undefined,
			returnUrl: ReturnUrlValidator.ensureValid(params.get("returnUrl")),
			// var search_ids = [...search.getAll('id')]
			factorCombination: params.getAll("factor") ?? [],
			recoveryUser: params.get("recoveryUser") ?? "",
			originApp: params.get("originApp") ?? ""
		}
	}
}

const forcedTraceId = document.getElementById("uiParameter")?.dataset.traceId ?? "";
let traceId:string = "";
if (forcedTraceId) {
	traceId = forcedTraceId;
} else {
	const sessionTraceId = window.sessionStorage.getItem("traceId");
	if (sessionTraceId && sessionTraceId !== "undefined") {
		traceId = sessionTraceId;
	} else {
		traceId= "" + Math.floor(Math.random() * 10000000000);
	}
}

window.sessionStorage.setItem("traceId", traceId);

export const { useGlobalState } = createGlobalState<State>({
	traceId,
	error: null,
	failedFactorChallengeResponse: null,
	isLoading: { loadingType: null },
	factorsCount: 0,
	solvedFactorCount:0,
	queryParameters: getQueryParameters(),
	modal: undefined,
	invalid: false,
	returnUrl: undefined,
});


