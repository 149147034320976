import {ErrorResponse} from "../interfaces/types";
import {apiUrl} from "./apiUrl";
import {useGlobalState} from "../globalState";
import {LoadingProps} from "../../../shared/interfaces";
import {sleep} from "react-hook-form/dist/utils/sleep";

interface ApiPostProps<T> extends ApiProps<T> {
  body: any | undefined;
}

interface ApiGetProps<T> extends ApiProps<T> {
}

interface ApiProps<T> extends ApiHookProps<T> {
  onError: (error: ErrorResponse, status: number|undefined, errorObject?: any) => void;
  onSuccess: (payload: T) => void;
  traceId: string;
  getIsInvalid?: () => boolean;
  setIsInvalid?: (valid:boolean) => void;
  setIsLoading?: (trigger: LoadingProps) => void;
  setGlobalError?: (error: any) => void;
  disableRetry?: boolean;
}

interface ApiHookProps<T> {
  apiPath: string;
  spinner?: boolean;
  onError?: (error: ErrorResponse, status: number|undefined, errorObject?: any) => void;
  onSuccess?: (payload: T) => void;
  disableRetry?: boolean;
}

function waitForEvent(timeout: number) {

  return new Promise((resolve, reject) => {

    function ap5Handler(event: Event) {
      document.removeEventListener("AP-5", ap5Handler);
      resolve(event);
    }

    setTimeout(() => {
      document.removeEventListener("AP-5", ap5Handler);
      reject(`Timeout: Event 'AP-5' rejected after ${timeout}ms.`);
    }, timeout);

    document.addEventListener("AP-5", ap5Handler);
  });
}

function getCookie(name: string): string | undefined {

    const cookiesEqualSeparated = `; ${document.cookie}`;
    const splitBySeparatorAndName = cookiesEqualSeparated.split(`; ${name}=`);
    if (splitBySeparatorAndName.length === 2) {

      const valuePart = splitBySeparatorAndName.pop();
      return valuePart && valuePart.split(';').shift();
    }
    return undefined;
}

function setAndGetForm(name: string, valueBase64: string|undefined|null) : string | undefined {

  let valueDecoded;
  const input = document.getElementById(name) as HTMLInputElement
  if (valueBase64 && valueBase64.length > 0) {
    valueDecoded = Buffer.from(valueBase64, 'base64').toString('utf-8');
    localStorage.setItem(name, valueDecoded);
    input && (input.value = valueDecoded);
  } else {
    input && (valueDecoded = input.value);
  }
  return valueDecoded;
}


async function setForm<S>(payload: S) {

  const lks = setAndGetForm('sir',  getCookie("sir"));
  const sir = setAndGetForm('lks',  getCookie("lks"));

  // @ts-ignore
  if (typeof googleAnalytics === 'function' && sir?.length > 0 && lks?.length > 0) {
    // @ts-ignore
    googleAnalytics();
    await waitForEvent(2000); // trigger with timeout 1s
  }

  return payload;
}

function getAkrf() :string  {
  let akrf: string;
  const akrfElement = document.getElementById("fcem") as HTMLInputElement;
  if (akrfElement && akrfElement.value) {
    akrf = akrfElement.value;
  } else {
    akrf = 'x48573c';
  }

  return akrf;
}

export async function setGoogleAnalyticsFormFromStorage() {

  const lks = getFromStorageAndSetInputValue("lks");
  const sir = getFromStorageAndSetInputValue("sir");

  // @ts-ignore
  if (typeof googleAnalytics === 'function' && sir?.length > 0 && lks?.length > 0) {
    // @ts-ignore
    googleAnalytics();
    await waitForEvent(1000); // trigger with timeout 1s
  }
}

function getFromStorageAndSetInputValue( name:string ) {

  let value= localStorage.getItem(name);

  (value && value.length > 0) && ((document.getElementById(name) as HTMLInputElement).value = value);

  return value;
}

/**
 * Hook for api calls with basic handling of errors.
 * @param propsOrPath apiPath or object from type ApiHookProps
 * @return [callFunction, payload]
 */
export function useApiPost<S>(propsOrPath: ApiHookProps<S> | string): (body: any | undefined) => Promise<S | false> {

  const [, setIsLoading] = useGlobalState("isLoading");
  const [globalError, setGlobalError] = useGlobalState("error");
  const [modal,] = useGlobalState("modal")
  const [traceId] = useGlobalState("traceId");
  const invalidGlobal = useGlobalState("invalid");
  const setIsInvalid = invalidGlobal[1];

  let props: ApiHookProps<S>;
  if (typeof propsOrPath === 'string') {
    props = {apiPath: propsOrPath};
  } else {
    props = propsOrPath;
  }

  let spinnerValue = (!(typeof propsOrPath !== 'string' && propsOrPath.spinner === false));

  if (modal || globalError) {
    if (document.activeElement) {
      (document.activeElement as HTMLElement).blur();
    }
    // return empty body if modal is open
    return () => {
      return new Promise<S | false>((resolve) => {
        resolve(false);
      });
    }
  }

  return (body) => {

    return ApiPost<S>({
      ...props,
      traceId,
      body : {...body, akrf: getAkrf() },
      setIsLoading,
      spinner : spinnerValue,
      setGlobalError,
      onSuccess: payload => {
        if (props.onSuccess) {
         props.onSuccess;
        }
      },
      onError: (error, status, errorObject) => {
        if (props.onError) {
          props.onError(error, status, errorObject);
        } else {
          setGlobalError(error);
        }
      }
    }).then(setForm);
  };
}


export function useApiGet<S = undefined>(propsOrPath: ApiHookProps<S> | string): () => Promise<S | false> {

  const [, setIsLoading] = useGlobalState("isLoading");
  const [, setGlobalError] = useGlobalState("error");
  const [traceId] = useGlobalState("traceId");
  const [invalid, setIsInvalid] = useGlobalState("invalid");

  let props: ApiHookProps<S>;
  if (typeof propsOrPath === 'string') {
    props = {apiPath: propsOrPath};
  } else {
    props = propsOrPath;
  }

  function getIsInvalid() : boolean {
    return invalid;
  }

  return () => {
    return ApiGet<S>({
      ...props, setIsLoading, setGlobalError, getIsInvalid, setIsInvalid, traceId,
      onSuccess: payload => {
        if (props.onSuccess) {
          props.onSuccess(payload);
        }
      },
      onError: (error, status, errorObject) => {
        if (props.onError) {
          props.onError(error, status, errorObject);
        } else {
          setGlobalError(error);
        }
      }
    });
  };
}

async function ApiCall<T>({ onSuccess, onError, setIsLoading, getIsInvalid, setIsInvalid, setGlobalError, spinner, traceId, disableRetry}: ApiProps<T>, endpoint: string, options: any): Promise<T | false> {

  if (getIsInvalid && getIsInvalid()) {
    if (setGlobalError) {
      setGlobalError("Session invalid");
    } else {
      console.error("Api call canceled session is invalid.");
    }
    return false;
  }

  if (spinner && setIsLoading) {
    setIsLoading({loadingType: "standard"});
  }

  const abortController = new AbortController()
  // Timeout set to 1 minute:
  const timeoutId = setTimeout(() => abortController.abort(), 60000)

  // add traceId to get url
  let url: string;
  if (endpoint.indexOf("?") === -1) {
    url =  endpoint + '?' + ( new URLSearchParams({"traceId": traceId} ));
  } else {
    url = endpoint + ( new URLSearchParams({"traceId": traceId} ));
  }

  let response;
  try {
    response = await fetch(url, {...options,  signal: abortController.signal });
  } catch (error) {
    clearTimeout(timeoutId);

    spinner && setIsLoading && setIsLoading({loadingType: null});

    if ((error as Error).name === "TypeError" || (error as Error).name === "AbortError" || disableRetry !== true) {

      const retryResult = async () :Promise<T|false> => {
        return new Promise<T|false>((resolve) => {
          const props = {
            onSubmit: async () => {

              setIsLoading && setIsLoading({ loadingType: "offline" });

              const apiProps: ApiProps<any> = {onSuccess, onError, setIsLoading, setGlobalError, traceId, spinner : false, apiPath:""};

              const result = await ApiCall<T>(apiProps, endpoint, options);

              console.log("retry result: ", result);
              if (result) {
                onSuccess(result);
              }

              setIsLoading && setIsLoading({ loadingType: null });
              resolve(result);
            }
          };

          setIsLoading && setIsLoading({ loadingType: "offline", props });
        });
      }

      return await retryResult();
    } else {

      console.error("Fetch api error: ", error);

      if (setGlobalError) {
        setGlobalError({errorId: "", error: "FETCH_API_ERROR", codes: []});
      } else {
        const error = new Error("Request fetch api failed.");
        throw {...error, name: "FETCH_API_ERROR"};
      }
      return false;
    }
  }
  clearTimeout(timeoutId);

  if (spinner && setIsLoading) {
    setIsLoading({loadingType: null});
  }

  if (response.ok) {
    const payload = await response.json();
    onSuccess(payload);

    setAndGetForm("lks", response.headers.get("X-LKS"));
    setAndGetForm("sir", response.headers.get("X-SIR"));

    return payload
  }

  const status = response.status;

  // Check for special incapsula errors
  // They are identified by status code 403 and a incapsula specific response body
  if (status == 403) {
    const textResponse = await response.text();
    const matches = textResponse.match(/^.*Incapsula incident ID: (.*)<\/iframe>.*$/);

    if (matches && matches.length == 2) {
      const incidentId = matches[1];
      console.warn("incapsula blocked the request incidentId: " + incidentId);

      if (setGlobalError) {
        setGlobalError({errorId: "", error: "INCAPSULA", placeholderValues: {incidentId}, codes: []});
      } else {
        const error = new Error("Request got blocked by incapsula firewall.");
        throw {...error, incidentId, name: "INCAPSULA"};
      }
      return false;
    }

    // Common 403 response
    console.warn("Unknown forbidden response", textResponse);

    if (setGlobalError) {
      setGlobalError({errorId: "", error: "FORBIDDEN", codes: []});
    } else {
      const error = new Error("Request FORBIDDEN.");
      throw {...error, name: "FORBIDDEN"};
    }

    return false;
  }

  // commonly maintenance mode page 404 detection (both nodes down) -> HTML page returned
  if (status == 404) {
    let responseText404 = await response.text();
    const matches = responseText404.match(/Sorry for the inconvenience, we are performing some maintenance at the moment/);
    if (matches && matches.length == 1) {
      if (setGlobalError) {
        setGlobalError({errorId: "", error: "MAINTENANCE", codes: []});
      } else {
        const error = new Error("Request failed duo maintenance on the service.");
        throw {...error, name: "MAINTENANCE"};
      }
    } else {
      if (setGlobalError) {
        setGlobalError({errorId: "", error: "PAGE_NOT_FOUND", codes: []});
      } else {
        const error = new Error("Requested resource could not be found.");
        throw {...error, name: "PAGE_NOT_FOUND"};
      }
    }

    return false;
  }

  if (status == 502) {
    if (setGlobalError) {
      setGlobalError({errorId: "", error: "PROXY_ERROR", codes: []});
    } else {
      const error = new Error("Request got blocked by proxy service.");
      throw {...error, name: "PROXY_ERROR"};
    }
    return false;
  }

  let errorResponseObject:any = await response.text();

  let errorResponse:ErrorResponse;

  try {
    errorResponse = JSON.parse(errorResponseObject) as unknown as ErrorResponse;
  } catch (error) {
    console.error("Could not parse JSON error object:", error);
    errorResponse = {errorId: "", error: "REQUEST_FAILED", codes: []};
  }

  if (setIsInvalid && errorResponse.invalidate) {
    console.error("Session has been invalidated from server.")
    window.sessionStorage.setItem("invalidErrorObjectFromStorage", errorResponseObject);
    setIsInvalid(true);
  }

  // Custom error handler?
  if (onError) {
    onError(errorResponse, status, errorResponse);
  } else {
    console.error("request failed duo to error:", errorResponse);
  }

  return false;
}

export async function ApiGet<T>(props: ApiGetProps<T>) {
  return ApiCall<T>(props,
      `${apiUrl}${props.apiPath}`,
      {
        method: 'GET',
        headers: {
          'content-type': 'application/json;charset=UTF-8',
        }
      });
}

export async function ApiPost<T>(props: ApiPostProps<T>) {
  return ApiCall<T>(props,
      `${apiUrl}${props.apiPath}`,
      {
        method: 'POST',
        headers: {
          'content-type': 'application/json;charset=UTF-8',
        },
        body: JSON.stringify(props.body)
      });
}