import React, {FunctionComponent, useEffect} from "react";

import "./style/style.scss";
import {CookiesProvider} from "react-cookie";
import {RoutingPage} from "./routing/RoutingPage";
import {useGlobalState} from "./GlobalState";
import {ErrorModal} from "./ErrorModal";
import {CookieBanner} from "./CookieBanner";
import {Loading} from "designsystem/src/components/Loading/Loading";
import {MainStyle} from "designsystem/src/components/MainStyle/MainStyle";
import {AccountSwitchHandler} from "./components/AccountSwitchHandler";
import {UserContextProvider} from "./components/UserContext";
import {useActiveTheme} from "designsystem/src/components/Redesign/activeTheme";

export const App: FunctionComponent = () => {

  const [isLoading,] = useGlobalState("isLoading");
  const {setActiveThemeFromStorage} = useActiveTheme();

  useEffect(() => {
    setActiveThemeFromStorage();
  });

  return (
      <>
        <MainStyle/>
        <CookiesProvider>
          <UserContextProvider>
            <RoutingPage/>
          </UserContextProvider>
        </CookiesProvider>
        <AccountSwitchHandler/>
        <ErrorModal/>
        <Loading loadingType={isLoading.loadingType}/>
        <CookieBanner/>
      </>
  );
}


