import React from 'react';
import {I18NButtonProps} from "../../../../shared/interfaces";
import {Button} from "../Button/Button";
import {useTranslation} from "react-i18next";

/**
 * Primary UI component for user interaction
 */
export const I18NButton : React.FC<I18NButtonProps> = (props) => {
    
    const {t} = useTranslation();

    return (
        <Button label={t(props.i18n)} mode={props.mode} className={props.className} onClick={props.onClick} disabled={props.disabled} border={props.border} id={props.id} type={props.type} hoverText={props.hoverText}/>
    );
};