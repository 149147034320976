import React from "react";
import "./onlyOneAuthInfo.scss";
import {Icon} from "../../Icon/Icon";
import {Paragraph} from "../../Paragraph/Paragraph";
import {iconType, OnlyOneAuthInfoProps} from "../../../../../shared/interfaces";
import {I18NParagraph} from "../../I18NParagraph/I18NParagraph";
import {I18NHeading} from "../../I18NHeading/I18NHeading";

export const OnlyOneAuthInfo : React.FC<OnlyOneAuthInfoProps> = (props) => {
    
    let displayedAuthenticators = ["touchid", "faceid", "fido-passkey", "windows_hello", "android" ];

    const renderOtherAuthenticators = () => {

        const getIconTypeBeAuthName = (authName : string) : iconType => {
            switch (authName){
                case "apple":
                    return "apple";
                case "touchid":
                    return "touchid";
                case "faceid":
                    return "faceid";
                case "fido-passkey":
                    return "fido-passkey";
                case "windows_hello":
                    return "windows_hello";
                case "android":
                    return "android";
                default:
                    return "fido-passkey";
            }
        }

        let filtered =  displayedAuthenticators.filter((auth) => auth !== props.authType)

        let row1 = filtered.map((auth, index) => {
            if(index < 3){
                return <Icon key={index} icon={getIconTypeBeAuthName(auth)} className={"icon-size"}/>
            }
            return <></>
        })

        let row2 = filtered.map((auth, index) => {
            if(index >= 3){
                return <Icon key={index} icon={getIconTypeBeAuthName(auth)} className={"icon-size"}/>
            }
            return <></>
        })

        return (<><div>{row1}</div><div>{row2}</div></>)
    }
    
    return (
        <div className={"onlyOneAuthInfo"}>
            <div className={"information"}>
                <I18NParagraph i18n={"authenticationInformation.addAnother.intro"}/>
            </div>
            <div className={"explanation"}>
                <div className={"current explanation-card"}>
                    <Icon icon={"success"} className={"icon-size"}/>
                    <I18NHeading i18n={"authenticationInformation.addAnother.firstAuthHeading"} type={"h3"}/>
                    <div className={"authInfo"}>
                        <Paragraph id="newly-added-fido-stick" text={props.authName}/>
                        <Icon icon={"fido-passkey"} className={"icon-size"}/>
                    </div>
                </div>
                <div className={"plus"}>
                    <Paragraph text={"+"}/>
                </div>
                <div className={"expected explanation-card"}>
                    <Icon icon={"warning"} className={"icon-size"}/>
                    <I18NHeading i18n={"authenticationInformation.addAnother.secondAuthHeading"} type={"h3"}/>
                    <div className={"authenticators"}>
                        { renderOtherAuthenticators() }
                    </div>
                </div>
            </div>
        </div>
    );
};
