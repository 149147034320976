export class ReturnUrlValidator {
  private static readonly validDomains: string[] = [
    // the leading "." is important, otherwise "haxxormerckgroup.com" would be a valid returnUrl
    '.merckgroup.com',
    '.emdgroup.com'
  ];

  private static readonly illegalCharacters: string[] = [';', '?', '@', '&', '=', '+', '$', ','];

  public static ensureValid(returnUrl: string|null|undefined): string | undefined {
    return this.isValid(returnUrl) ? returnUrl! : undefined;
  }

  public static isValid(returnUrl: string|null|undefined): boolean {
    try {
      if (!returnUrl) {
        // empty string, null or undefined
        return false;
      }

      const url = new URL(returnUrl);

      if (url.protocol !== 'https:') {
        // wrong protocol
        return false;
      }

      const host = url.hostname;
      if (!host) {
        // host is empty
        return false;
      }

      if (!this.validDomains.some(domain => host.endsWith(domain))) {
        // wrong domain
        return false;
      }

      if (this.illegalCharacters.some(char => returnUrl.includes(char))) {
        // illegal characters
        return false;
      }

      return true;
    } catch (e) {
      // other invalid URL format
      return false;
    }
  }
}
