import React from 'react';
import './icon.scss';

import {IconProps} from "../../../../shared/interfaces";
import account from "../../../../assets/svg/account.svg";
import android from "../../../../assets/svg/android.svg";
import apple from "../../../../assets/svg/apple.svg";
import faceid from "../../../../assets/svg/apple-face-id.svg";
import touchid from "../../../../assets/svg/apple-touch-id.svg";
import arrow from "../../../../assets/svg/arrow.svg";
import checkmark_purple from "../../../../assets/svg/checkmark_purple.svg";
import checkmark_purple_thin from "../../../../assets/svg/checkmark_purple_thin.svg";
import checkmark_yellow from "../../../../assets/svg/checkmark_yellow.svg";
import cp_manage from "../../../../assets/svg/cp_manage.svg";
import cp_password from "../../../../assets/svg/cp_password.svg";
import cp_password_owned_ctrl from "../../../../assets/svg/cp_password_owned_ctrl.svg";
import cp_onboarding from "../../../../assets/img/cp_onboarding.svg"
import cp_problems from "../../../../assets/svg/cp_problems.svg";
import cross_cyan from "../../../../assets/svg/cross-cyan.svg";
import cross_purple from "../../../../assets/svg/cross-purple.svg";
import cross_red from "../../../../assets/svg/cross-red.svg";
import cross_yellow from "../../../../assets/svg/cross-yellow.svg";
import plus_purple from "../../../../assets/svg/plus-purple.svg";
import minus_purple from "../../../../assets/svg/minus-purple.svg";
import feedback from "../../../../assets/svg/feedback.svg";
import help from "../../../../assets/svg/help.svg";
import hero_authenticated from "../../../../assets/svg/hero-authenticated.svg";
import hero_done from "../../../../assets/svg/hero-done.svg";
import hero_welcome from "../../../../assets/svg/hero-welcome.svg";
import error from "../../../../assets/svg/icon-error.svg";
import menu from "../../../../assets/svg/menu.svg";
import empty_circle from "../../../../assets/svg/empty-circle.svg";
import check_circle from "../../../../assets/svg/check-circle.svg";
import x_circle from "../../../../assets/svg/x-circle.svg";
import x_circle_red from "../../../../assets/svg/x-circle-red.svg";
import logout from "../../../../assets/svg/logout.svg";
import globe from "../../../../assets/svg/globe.svg";
import camera from "../../../../assets/svg/camera.svg";
import eye_open from "../../../../assets/svg/eye_open.svg";
import eye_closed from "../../../../assets/svg/eye_closed.svg";
import search from "../../../../assets/svg/search.svg";
import home from "../../../../assets/svg/home.svg";
import userguide from "../../../../assets/svg/userguide.svg";
import book from "../../../../assets/svg/book.svg";
import user from "../../../../assets/svg/user.svg";
import users from "../../../../assets/svg/users.svg";
import warning from "../../../../assets/svg/icon-warning.svg";
import success from "../../../../assets/svg/icon-success.svg";
import info from "../../../../assets/svg/icon-info.svg";
import sad from "../../../../assets/svg/none.svg";
import other from "../../../../assets/svg/other.svg";
import otherDisabled from "../../../../assets/svg/other-disabled.svg";
import fidoPassKey from "../../../../assets/svg/fido-passkey.svg";
import fidoPassKeyDisabled from "../../../../assets/svg/fido-passkey-disabled.svg";
import appleDisabled from "../../../../assets/svg/apple-disabled.svg";
import windowsDisabled from "../../../../assets/svg/windows-disabled.svg";
import androidDisabled from "../../../../assets/svg/android-disabled.svg";
import securitykey from "../../../../assets/svg/securitykey.svg";
import step_1 from "../../../../assets/svg/step-1.svg";
import step_2_fido from "../../../../assets/svg/step-2-fido.svg";
import step_2_onboarding from "../../../../assets/svg/step-2-onboarding.svg";
import step_2_password from "../../../../assets/svg/step-2-password.svg";
import step_3_fido from "../../../../assets/svg/step-3-fido.svg";
import step_3_onboarding from "../../../../assets/svg/step-3-onboarding.svg";
import step_3_password from "../../../../assets/svg/step-3-password.svg";
import step_3_detached from "../../../../assets/svg/step-3-detached.svg"
import smiley_unhappy from "../../../../assets/svg/smiley-unhappy.svg";
import windows from "../../../../assets/svg/windows.svg";
import windows_hello from "../../../../assets/svg/windows-hello.svg";
import loading_cyan from "../../../../assets/gifs/loading-cyan.gif";
import loading_yellow from "../../../../assets/gifs/loading-yellow.gif";
import loading_purple from "../../../../assets/gifs/loading-purple.gif";
import device_switch_1 from "../../../../assets/gifs/device_switch_01.gif";
import device_switch_2 from "../../../../assets/gifs/device_switch_02.gif";
import device_switch_3 from "../../../../assets/gifs/device_switch_03.gif";
import android_authenticator_animation from "../../../../assets/gifs/android_authenticator_animation.gif";
import fido_hardwaretoken_animation from "../../../../assets/gifs/fido_hardwaretoken_animation.gif";
import iphone_fido_animation from "../../../../assets/gifs/iphone_fido_animation.gif";
import macbook_touchid_animation from "../../../../assets/gifs/macbook_touchid_animation.gif";
import windows_hello_animation from "../../../../assets/gifs/windows_hello_animation.gif";
import fidodemo_start from "../../../../assets/svg/fidodemo-start.svg";
import copy_to_clipboard from   "../../../../assets/svg/copy_to_clipboard.svg";

import windows_with_bluetooth_1_de from "../../../../assets/webp/windows-with-bluetooth-1-de.webp";
import windows_with_bluetooth_2_de from "../../../../assets/webp/windows-with-bluetooth-2-de.webp";
import windows_with_bluetooth_1_en from "../../../../assets/webp/windows-with-bluetooth-1-en.webp";
import windows_with_bluetooth_2_en from "../../../../assets/webp/windows-with-bluetooth-2-en.webp";
import windows_without_bluetooth_1_de from "../../../../assets/webp/windows-without-bluetooth-1-de.webp";
import windows_without_bluetooth_2_de from "../../../../assets/webp/windows-without-bluetooth-2-de.webp";
import windows_without_bluetooth_1_en from "../../../../assets/webp/windows-without-bluetooth-1-en.webp";
import windows_without_bluetooth_2_en from "../../../../assets/webp/windows-without-bluetooth-2-en.webp";
import macos_without_bluetooth_1_de from "../../../../assets/webp/macos-without-bluetooth-1-de.webp";
import macos_without_bluetooth_2_de from "../../../../assets/webp/macos-without-bluetooth-2-de.webp";
import macos_without_bluetooth_1_en from "../../../../assets/webp/macos-without-bluetooth-1-en.webp";
import macos_without_bluetooth_2_en from "../../../../assets/webp/macos-without-bluetooth-2-en.webp";
import macos_with_bluetooth_1_de from "../../../../assets/webp/macos-with-bluetooth-1-en.webp";
import macos_with_bluetooth_1_en from "../../../../assets/webp/macos-with-bluetooth-1-en.webp";

/**
 * Primary UI component for user interaction
 */
export const Icon : React.FC<IconProps> = (props) => {
    
    let iconSelected : string = "";
    let sizeStyle = props.sizeInPx ? {width: props.sizeInPx + "px", height: props.sizeInPx + "px"} : {};
    
    switch (props.icon) {
        case "account" : {
            iconSelected = account;
            break;
        }
        case "android" : {
            iconSelected = android;
            break;
        }
        case "apple" : {
            iconSelected = apple;
            break;
        }
        case "touchid" : {
            iconSelected = touchid;
            break;
        }
        case "faceid" : {
            iconSelected = faceid;
            break;
        }
        case "arrow" : {
            iconSelected = arrow;
            break;
        }
        case "checkmark_purple_thin" : {
            iconSelected = checkmark_purple_thin;
            break;
        }
        case "checkmark_purple" : {
            iconSelected = checkmark_purple;
            break;
        }
        case "checkmark_yellow" : {
            iconSelected = checkmark_yellow;
            break;
        }
        case "cp_manage" : {
            iconSelected = cp_manage;
            break;
        }
        case "cp_password" : {
            iconSelected = cp_password;
            break;
        }
        case "cp_password_owned_ctrl" : {
            iconSelected = cp_password_owned_ctrl;
            break;
        }
        case "cp_onboarding": {
            iconSelected = cp_onboarding;
            break;
        }
        case "cp_problems" : {
            iconSelected = cp_problems;
            break;
        }
        case "cross_cyan" : {
            iconSelected = cross_cyan;
            break;
        }
        case "cross_purple" : {
            iconSelected = cross_purple;
            break;
        }
        case "cross_red" : {
            iconSelected = cross_red;
            break;
        }
        case "cross_yellow" : {
            iconSelected = cross_yellow;
            break;
        }
        case "plus_purple" : {
            iconSelected = plus_purple;
            break;
        }
        case "minus_purple" : {
            iconSelected = minus_purple;
            break;
        }
        case "feedback" : {
            iconSelected = feedback;
            break;
        }
        case "help" : {
            iconSelected = help;
            break;
        }
        case "hero_authenticated" : {
            iconSelected = hero_authenticated;
            break;
        }
        case "hero_done" : {
            iconSelected = hero_done;
            break;
        }
        case "hero_welcome" : {
            iconSelected = hero_welcome;
            break;
        }
        case "menu" : {
            iconSelected = menu;
            break;
        }
        case "empty-circle" : {
            iconSelected = empty_circle;
            break;
        }
        case "x-circle" : {
            iconSelected = x_circle;
            break;
        }
        case "x-circle-red" : {
            iconSelected = x_circle_red;
            break;
        }
        case "check-circle" : {
            iconSelected = check_circle;
            break;
        }
        case "logout" : {
            iconSelected = logout;
            break;
        }
        case "globe" : {
            iconSelected = globe;
            break;
        }
        case "camera" : {
            iconSelected = camera;
            break;
        }
        case "eye_open" : {
            iconSelected = eye_open;
            break;
        }
        case "eye_closed" : {
            iconSelected = eye_closed;
            break;
        }
        case "search" : {
            iconSelected = search;
            break;
        }
        case "home" : {
            iconSelected = home;
            break;
        }
        case "userguide" : {
            iconSelected = userguide;
            break;
        }
        case "book" : {
            iconSelected = book;
            break;
        }
        case "user" : {
            iconSelected = user;
            break;
        }
        case "users" : {
            iconSelected = users;
            break;
        }
        case "error" : {
            iconSelected = error;
            break;
        }
        case "warning" : {
            iconSelected = warning;
            break;
        }
        case "success" : {
            iconSelected = success;
            break;
        }
        case "info" : {
            iconSelected = info;
            break;
        }
        case "sad" : {
            iconSelected = sad;
            break;
        }
        case "other" : {
            iconSelected = other;
            break;
        }
        case "other-disabled" : {
            iconSelected = otherDisabled;
            break;
        }
        case "fido-passkey" : {
            iconSelected = fidoPassKey;
            break;
        }
        case "fido-passkey-disabled" : {
            iconSelected = fidoPassKeyDisabled;
            break;
        }
        case "apple-disabled" : {
            iconSelected = appleDisabled;
            break;
        }
        case "windows-disabled" : {
            iconSelected = windowsDisabled;
            break;
        }
        case "android-disabled" : {
            iconSelected = androidDisabled;
            break;
        }
        case "securitykey" : {
            iconSelected = securitykey;
            break;
        }
        case "step_1" : {
            iconSelected = step_1;
            break;
        }
        case "step_2_fido" : {
            iconSelected = step_2_fido;
            break;
        }
        case "step_2_onboarding" : {
            iconSelected = step_2_onboarding;
            break;
        }
        case "step_2_password" : {
            iconSelected = step_2_password;
            break;
        }
        case "step_3_fido" : {
            iconSelected = step_3_fido;
            break;
        }
        case "step_3_onboarding" : {
            iconSelected = step_3_onboarding;
            break;
        }
        case "step_3_password" : {
            iconSelected = step_3_password;
            break;
        }
        case "step_3_detached" : {
            iconSelected = step_3_detached;
            break;
        }
        case "loading_cyan" : {
            iconSelected = loading_cyan;
            break;
        }
        case "loading_yellow" : {
            iconSelected = loading_yellow;
            break;
        }
        case "loading_purple" : {
            iconSelected = loading_purple;
            break;
        }
        case "device_switch_1" : {
            iconSelected = device_switch_1;
            break;
        }
        case "device_switch_2" : {
            iconSelected = device_switch_2;
            break;
        }
        case "device_switch_3" : {
            iconSelected = device_switch_3;
            break;
        }
        case "android_authenticator_animation" : {
            iconSelected = android_authenticator_animation;
            break;
        }
        case "fido_hardwaretoken_animation" : {
            iconSelected = fido_hardwaretoken_animation;
            break;
        }
        case "iphone_fido_animation" : {
            iconSelected = iphone_fido_animation;
            break;
        }
        case "macbook_touchid_animation" : {
            iconSelected = macbook_touchid_animation;
            break;
        }
        case "windows_hello_animation" : {
            iconSelected = windows_hello_animation;
            break;
        }
        case "windows" : {
            iconSelected = windows;
            break;
        }
        case "windows_hello" : {
            iconSelected = windows_hello;
            break;
        }
        case "smiley_unhappy" : {
            iconSelected = smiley_unhappy;
            break;
        }
        case "fidodemo-start" : {
            iconSelected = fidodemo_start;
            break;
        }
        case "windows_with_bluetooth_1_de" : {
            iconSelected = windows_with_bluetooth_1_de;
            break;
        }
        case "windows_with_bluetooth_2_de" : {
            iconSelected = windows_with_bluetooth_2_de;
            break;
        }
        case "windows_with_bluetooth_1_en" : {
            iconSelected = windows_with_bluetooth_1_en;
            break;
        }
        case "windows_with_bluetooth_2_en" : {
            iconSelected = windows_with_bluetooth_2_en;
            break;
        }
        case "windows_without_bluetooth_1_de" : {
            iconSelected = windows_without_bluetooth_1_de;
            break;
        }
        case "windows_without_bluetooth_2_de" : {
            iconSelected = windows_without_bluetooth_2_de;
            break;
        }
        case "windows_without_bluetooth_1_en" : {
            iconSelected = windows_without_bluetooth_1_en;
            break;
        }
        case "windows_without_bluetooth_2_en" : {
            iconSelected = windows_without_bluetooth_2_en;
            break;
        }
        case "macos_without_bluetooth_1_de" : {
            iconSelected = macos_without_bluetooth_1_de;
            break;
        }
        case "macos_without_bluetooth_2_de" : {
            iconSelected = macos_without_bluetooth_2_de;
            break;
        }
        case "macos_without_bluetooth_1_en" : {
            iconSelected = macos_without_bluetooth_1_en;
            break;
        }
        case "macos_without_bluetooth_2_en" : {
            iconSelected = macos_without_bluetooth_2_en;
            break;
        }
        case "macos_with_bluetooth_1_de" : {
            iconSelected = macos_with_bluetooth_1_de;
            break;
        }
        case "macos_with_bluetooth_1_en" : {
            iconSelected = macos_with_bluetooth_1_en;
            break;
        }
        case "copy_to_clipboard" : {
            iconSelected = copy_to_clipboard;
            break;
        }
    }
    
    return (
        <img onClick={props.onClick ? props.onClick: ()=>{}} crossOrigin="anonymous" id={props.id} className={["icon", props.className].join(" ")} src={iconSelected} alt={ props.alt ? props.alt : iconSelected + " icon"}
             style={sizeStyle} title={props.titleText}/>
    );
};