import "./button.scss";

import React, { FC, ReactNode } from "react";
import { Spacing } from "../Spacing";

export interface ButtonProps {
  mode?:
    | "yellow"
    | "purple"
    | "white"
    | "red"
    | "transparent"
    | "link"
    | "error"
    | "success"
    | "warning";
  backgroundColor?: string;
  label: string;
  className?: string;
  disabled?: boolean;
  // @ts-ignore
  onClick?: (e: MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  border?: boolean;
  id?: string;
  type?: "button" | "submit" | "reset" | undefined;
  hoverText?: string;
  form?: string;
  icon?: ReactNode;
  loading?: boolean;
  style?: React.CSSProperties;
}

/**
 * Primary UI component for user interaction
 */
export const Button: React.FC<ButtonProps> = (props) => {
  let cssMode: string = "";

  if (props.mode) {
    cssMode = `storybook-button--${props.mode}`;
  }

  const Content: FC<{}> = () => (
    <>
      {props?.icon ? (
        <Spacing alignItems="center">
          {props?.icon}
          {props.label}
        </Spacing>
      ) : (
        props.label
      )}
    </>
  );

  return (
    <button
      id={props.id}
      type={props.type ? props.type : "button"}
      disabled={props.disabled}
      form={props.form}
      className={[
        "storybook-button",
        cssMode,
        props.className,
        props.border ? `storybook-button--border` : ``,
      ].join(" ")}
      onClick={props.onClick}
      title={props.hoverText}
      style={props.style}
    >
      {props.loading ? (
        <Spacing flexWrap="nowrap" alignItems="center" gap="8" justifyContent="center">
          {props?.loading && (
            <span className="loading-wrapper">
              <span className="loader"></span>
            </span>
          )}
          <Content />
        </Spacing>
      ) : (
        <Content />
      )}
    </button>
  );
};
