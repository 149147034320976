import React, {useState} from "react";
import {IconButton} from "../IconButton/IconButton";
import {ExpanderProps} from "../../../../shared/interfaces";
import {useTranslation} from "react-i18next";

/**
 * Primary UI component for user interaction
 */
export const Expander: React.FC<ExpanderProps> = (props) => {

  const [expanded, setExpanded] = useState<boolean>(props.initiallyExpanded ?? false);
  const {t} = useTranslation(); 

  return (
      <div className={['storybook-expander', props.className].join(' ')}>
        <IconButton icon={"arrow"} headlineSize={props.headlineSize} label={t(props.header)} onClick={() => setExpanded(!expanded)} initiallyActive={props.initiallyExpanded}/>
        {expanded && props.children}
      </div>
  );
};
