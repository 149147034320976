import React from 'react';
import './heading.scss';
import {HeadingProps} from "../../../../shared/interfaces";

/**
 * Primary UI component for user interaction
 */
export const Heading : React.FC<HeadingProps> = (props) => {
    
    switch (props.type) {
        case "h1":
            return ( <h1 id={props.id}className={props.className}>{props.text}</h1>);
        case "h2":
            return ( <h2 id={props.id}className={props.className}>{props.text}</h2>);
        case "h3":
            return ( <h3 id={props.id}className={props.className}>{props.text}</h3>);
        case "h4":
            return ( <h4 id={props.id}className={props.className}>{props.text}</h4>);
        case "h5":
            return ( <h5 id={props.id}className={props.className}>{props.text}</h5>);
        case "h6":
            return ( <h6 id={props.id}className={props.className}>{props.text}</h6>);
    }
};
