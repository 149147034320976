import React from 'react';
import './radioButtonGroup.scss';
import {RadioButtonGroupProps} from "../../../../shared/interfaces";

export const RadioButtonGroup: React.FC<RadioButtonGroupProps> = (props) => {
    return (
        <div className={["radiobutton-group", props.className].join(" ")}>
            {props.children}
        </div>
    )
};
