import {Page, Pages} from "../interfaces/types";
import {contextPath} from "./apiUrl";

/**
 * Action definition dictionary
 */
export const pages:Pages = {
  ShowUserDataAction: {
    name: "FidoCredentials",
    path: "/fidoCredentials",
  },
  ProfileAction: {
    name: "ProfileAction",
    path: "/profileAction",
  },
  Intro: {
    name: "FidoIntro",
    path: "/fidoIntro"
  },
  None: {
    name: "AccountManagementPortal",
    path: "/",
  }
}

export function determineCurrentPage() {

  let currentPage: Page = pages.None;
  // determine current action via location.
  for (let key in pages) {
    const page = pages[key];
    const pathname = `${contextPath}${page.path}`;
    if (window.location.pathname.startsWith(pathname)) {
      currentPage = page;
      break;
    }
  }
  return currentPage;
}

export const getClientErrors = () => {
  return ["UserCanNotBeRegistered", "Fido2.registration.credentialCreateInvalidStateError"];
}

export const getCurrentDomainWithUpperCompanyName = () => {

  if (window.location.hostname.search("merckgroup.com") > 0) {
    return "MERCKgroup.com";
  }
  return "EMDgroup.com";
}

export const getCurrentOppositeDomainWithUpperCompanyName = () => {

  if (window.location.hostname.search("merckgroup.com") > 0) {
    return "EMDgroup.com";
  }
  return "MERCKgroup.com";
}

export const getCompanyDomain = () => {
  if (window.location.hostname.search("merckgroup.com") > 0) {
    return "merckgroup.com";
  }
  return "emdgroup.com";
}

export const getCurrentCompanyName = () => {
    if (typeof window.globals.brand === "string") {
        if (window.globals.brand.toLowerCase().trim() === "merck") {
            return "Merck";
        }
        if (window.globals.brand.toLowerCase().trim() === "emd") {
            return "EMD";
        }
    }
    console.error("Your company could not be determined. EMD is set as default.");
    return "EMD";
}
