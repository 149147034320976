import {getPortalUrl} from "./apiUrl";
import {UserInfo} from "../interfaces/types";

/**
 * @param user
 * @param ownedCtrlAction this is an optional parameter that will be used to control if the user should be redirected to the fidoregisterownedctrl page or the normal one. If this is ommited the decision will be made based on user.hasOwnedAccounts.
 */
export const navigateToFidoRegister = (user: UserInfo, ownedCtrlAction?: boolean) => {
  if (typeof ownedCtrlAction === "undefined") {
    ownedCtrlAction = user.hasOwnedAccounts;
  }

  if (ownedCtrlAction) {
    window.location.assign(
        "/fidoregisterownedctrl" +
        ("?upnToAuthenticate=" + decodeURIComponent(user.preferredUsername)) +
        "&returnUrl=" +
        decodeURIComponent(getPortalUrl()) +
        "&factor=FIDO2_USER_VERIFICATION",
    );
  } else {
    /*
     * This is disabled duo to user find it too difficult to go back and choose an other sign in method.
    let preSelectedFactor = "";
    if (fidoData.authenticators?.length > 0) {
      preSelectedFactor = "&factor=FIDO2_USER_VERIFICATION";
    }
    window.location.assign("/fidoregister" + (auth.user ? "?upnToAuthenticate=" + decodeURIComponent(auth.user?.preferredUsername) : "") + "&returnUrl=" + getPortalUrl() + preSelectedFactor);
    */
    window.location.assign(
        "/fidoregister" +
        ("?upnToAuthenticate=" + decodeURIComponent(user.preferredUsername)) +
        "&returnUrl=" +
        decodeURIComponent(getPortalUrl()),
    );
  }
};

/**
 * @param user
 * @param ownedCtrlAction this is an optional parameter that will be used to control if the user should be redirected to the passwordresetownedctrl page or the normal one. If this is ommited the decision will be made based on user.hasOwnedAccounts.
 */
export const navigateToPasswordReset = (user: UserInfo, ownedCtrlAction?: boolean) => {
  if (typeof ownedCtrlAction === "undefined") {
    ownedCtrlAction = user.hasOwnedAccounts;
  }

  if (ownedCtrlAction) {
    window.location.assign(
        "/passwordresetownedctrl" +
        ("?upnToAuthenticate=" + decodeURIComponent(user.preferredUsername)) +
        "&returnUrl=" +
        decodeURIComponent(getPortalUrl()) +
        "&factor=FIDO2_USER_VERIFICATION",
    );
  } else {
    window.location.assign(
        "/passwordreset" +
        ("?upnToAuthenticate=" + decodeURIComponent(user.preferredUsername)) +
        "&returnUrl=" +
        decodeURIComponent(getPortalUrl()) +
        "&factor=FIDO2_USER_VERIFICATION",
    );
  }
};

export const navigateToAzureMfaRegistration = (user: UserInfo, letUserEnterUpn: boolean) => {
  if (letUserEnterUpn) {
    window.location.assign("/azureregistration");
  } else {
    window.location.assign(
        "/azureregistration" +
        ("?upnToAuthenticate=" + decodeURIComponent(user.preferredUsername)) +
        "&returnUrl=" +
        decodeURIComponent(getPortalUrl()) +
        "&factor=FIDO2_USER_VERIFICATION",
    );
  }
};

export const navigateToFidoDemo = (username: string) => {
  const fidoDemoOrigin = location.origin.replace(
      "https://me",
      "https://fidodemo",
  );
  const fullFidoDemoUrl = `${fidoDemoOrigin}?return_url=${getPortalUrl()}&username=${username}`;
  window.location.assign(fullFidoDemoUrl);
};
